import { IconographyNames } from '@vizir-banking/design-system';
import {
  Icon,
  Skeleton as BaseSkeleton,
  SkeletonVariants,
  TableCell as BaseTableCell,
  TableRow as BaseTableRow,
  TableTagCell as BaseTableTagCell,
  Typography,
  TypographyVariants,
} from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import { SplitModeEnum } from '~/api/constants';
import { FragmentCol } from '~/styles/global-styles';
import { getColor } from '~/styles/theme/get-theme';

const BaseIcon = styled(Icon).attrs({
  color: getColor('neutral', 'darkest'),
})`
  width: 24px;
  height: 24px;
`;

export const CheckboxWrapper = styled(FragmentCol)`
  width: 100%;
  height: 60px;
  justify-content: center;
  align-items: center;
`;

export const SelectAllText = styled(Typography).attrs({
  variant: TypographyVariants.SUBTITLE,
})`
  color: ${getColor('neutral', 'dark')};
`;

export const TableHeadRow = styled(BaseTableRow)`
  background-color: ${getColor('neutral', 'lighter')};
`;

export const TableBodyRow = styled(BaseTableRow)``;

export const TableTagCell = styled(BaseTableTagCell).attrs(({ hasTrailingIcon }) => ({
  hasTrailingIcon: hasTrailingIcon,
  trailingIconName: IconographyNames.infoCircle,
  trailingIconColor: getColor('error', 'medium'),
}))`
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const AnimatedTableCell = styled(BaseTableCell)<{ isCopied?: boolean }>`
  background-color: ${({ isCopied, theme }) =>
    isCopied ? theme.designSystem.palette.neutral.medium : 'inherit'};

  ${({ isCopied }) => isCopied && 'transition: background-color 2s ease-in-out;'}
  ${({ isCopied }) => isCopied && 'animation: animation 2s ease-in-out;'}

  @keyframes animation {
    0% {
      background-color: inherit;
    }
    50% {
      background-color: ${({ theme }) => theme.designSystem.palette.neutral.medium};
    }
    100% {
      background-color: inherit;
    }
  }
`;

export const TableCell = styled(BaseTableCell)<{ hasError?: boolean }>`
  background-color: ${({ hasError, theme }) =>
    hasError ? theme.designSystem.palette.neutral.medium : 'inherit'};
  color: ${({ hasError, theme }) => (hasError ? theme.designSystem.palette.error.dark : 'inherit')};
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ErrorIcon = styled(BaseIcon).attrs({
  iconName: IconographyNames.errorCircle,
  color: getColor('neutral', 'dark'),
})`
  width: 48px;
  height: 48px;
`;

const getRechargeIcon = (splitMode: { splitMode: string }) => {
  const icon = {
    [SplitModeEnum.INSTANTANEOUS]: IconographyNames.flash,
    [SplitModeEnum.SCHEDULED]: IconographyNames.calendar,
    [SplitModeEnum.MANUAL]: IconographyNames.userApprove,
  };
  return icon[splitMode.splitMode as keyof typeof icon];
};

export const RechargeIcon = styled(BaseIcon).attrs((splitMode) => ({
  iconName: getRechargeIcon(splitMode),
}))``;

export const CopyIcon = styled(BaseIcon).attrs({
  iconName: IconographyNames.copy,
})``;

export const Skeleton = styled(BaseSkeleton).attrs({
  variant: SkeletonVariants.tag,
})`
  height: 32px;
  width: 120px;
`;

export const EmptyTableWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const EmptyTableCell = styled(TableCell).attrs({})`
  text-align: center;
  height: 340px;
`;

export const EmptyText = styled(Typography).attrs({
  variant: TypographyVariants.HEADING_M,
})`
  color: ${getColor('neutral', 'dark')};
`;

export const WarningIcon = styled(BaseIcon).attrs({
  iconName: IconographyNames.exclamationCircle,
  color: getColor('error', 'dark'),
})`
  width: 24px;
  height: 24px;
`;
