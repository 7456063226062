import { CardBatchStatusEnum, SplitModeEnum } from '~/api/constants';
import { ActionsTable, StatusTable } from '~/components/tables/types';

export const enUsComponentTranslations = {
  components: {
    sideBar: {
      rhHome: 'Home',
      issuerHome: 'Home',
      collaborators: 'Collaborators',
      listEmployee: 'Collaborators list',
      listBatches: 'Registrations',
      recharge: 'Balance recharge',
      rhPermissions: 'Permissions',
      issuerPermissions: 'Permissions',
      cards: 'Request cards',
      settings: 'Settings',
      address: 'Addresses',
      company: 'Company',
      list: 'Companies list',
      new: 'Register new company',
      goBack: 'Back to the main portal',
      rh: '*RH* Portal',
      issuer: '*Benefits* Portal',
    },
    topBar: {
      help: 'Help',
      dockPaymentSolutions: 'Dock Payment Solutions',
      admin: 'Admin',
      operator: 'Operator',
      viewer: 'Viewer',
    },
    verificationCode: {
      title: 'Enter the code we sent to your registered email',
      resendCode: 'Resend code in {{time}} seconds',
      resend: 'Resend',
      error: 'Failed to send, ',
      tryAgain: 'try again!',
    },
    tables: {
      header: {
        status: 'Status',
        name: 'Name',
        legalName: 'Legal name',
        document: 'Document',
        email: 'Email',
        role: 'Permission',
        profile: 'Profile',
        id: 'ID',
        createdAt: 'Creation date',
        quantity: 'Quantity',
        amount: 'Amount',
        actions: 'Actions',
        splitMode: 'Split Mode',
        order: 'Order',
      },
      status: {
        [StatusTable.ACTIVE]: 'Active',
        [StatusTable.PENDING]: 'Pending',
        [StatusTable.INACTIVE]: 'Inactive',
        [StatusTable.PROCESSED]: 'Processed',
        [StatusTable.PROCESSING]: 'Processing',
        [StatusTable.ERROR]: 'Error',
        [StatusTable.ABORTED]: 'Canceled',
        [StatusTable.PARTIAL_PROCESSED]: 'Partially processed',
        [StatusTable.FAILED]: 'Failed',
        [StatusTable.UNKNOWN]: 'Unknown error',
        [StatusTable.NOT_PROCESSED]: 'Not processed',
        [StatusTable.DECLINED]: 'Declined',
        [StatusTable.APPROVED]: 'Approved',
        [StatusTable.MIGRATED]: 'Migrated',
        [StatusTable.RETRY]: 'Retry',
        order: {
          [StatusTable.ORDER.PROCESSED]: 'Fully distributed',
          [StatusTable.ORDER.PROCESSING]: 'Processing order',
          [StatusTable.ORDER.PARTIAL_PROCESSED]: 'Partially distributed',
          [StatusTable.ORDER.FAILED]: 'Failed release',
          [StatusTable.ORDER.CANCELED]: 'Canceled',
          [StatusTable.ORDER.PENDING_PAYMENT]: 'Pending payment',
          [StatusTable.ORDER.READY_TO_RELEASE]: 'Ready to release',
          [StatusTable.ORDER.PROCESSING_SPREADSHEET]: 'Processing spreadsheet',
          [StatusTable.ORDER.SPREADSHEET_FAILURE]: 'Processing failure',
          [StatusTable.ORDER.UNKNOWN]: 'Unknown error',
        },
      },
      actions: {
        [ActionsTable.REMOVE_ACCESS]: 'Remove access',
        [ActionsTable.RESEND_INVITATION]: 'Resend invitation',
        [ActionsTable.VIEW_DETAILS]: 'View details',
        [ActionsTable.ACCESS_PORTAL]: 'Access portal',
        [ActionsTable.RELEASE_ORDER]: 'Release order',
        [ActionsTable.EDIT_ORDER]: 'Edit order',
      },
      proposals: {
        header: {
          line: 'Line',
          status: 'Status',
          personFullName: 'Full name',
          cpf: 'CPF',
          email: 'Email',
          birthDate: 'Birth date',
          phone: 'Phone',
          addressPostalCode: 'Postal code',
          addressStreet: 'Street',
          addressNumber: 'Number',
          addressComplement: 'Address complement',
          addressNeighborhood: 'Neighborhood',
          addressCity: 'City',
          addressAdministrativeAreaCode: 'Administrative area code',
          addressCountryCode: 'Country code',
          motherName: 'Mother name',
          preferredName: 'Preferred name',
          gender: 'Gender',
          emancipatedMinor: 'Emancipated minor',
          maritalStatus: 'Marital status',
        },
      },
      splitMode: {
        [SplitModeEnum.INSTANTANEOUS]: 'Instantaneous',
        [SplitModeEnum.SCHEDULED]: 'Scheduled to {{date}}',
        [SplitModeEnum.MANUAL]: 'Manual',
        unknown: 'Unknown',
      },
      final: 'Final {{value}}',
      noData: 'No data found',
      noProposal: 'No proposal found',
    },
    table: {
      permission: {
        table: {
          status: 'Status',
          name: 'Full name',
          document: 'CPF',
          email: 'Email',
          role: 'Permission',
          actions: 'Actions',
        },
        status: {
          active: 'Active',
          pending: 'Pending',
        },
        action: {
          removeAccess: 'Remove access',
          resendInvitation: 'Resend invitation',
        },
        role: {
          admin: 'Admin',
          operator: 'Operator',
          viewer: 'Viewer',
        },
        profile: {
          company_rh: 'RH',
          client_consultant: 'Operator',
          manager: 'Manager',
        },
        modal: {
          confirmDelete: 'Are you sure you want to remove the access of',
          confirmDeleteSelf:
            'Are you sure you want to remove your own access? You will be immediately logged out of the portal.',
          cancel: 'Cancel',
          accessRemoved: 'Access removed successfully!',
          invitationSent: 'Invitation sent successfully to',
        },
      },
      batches: {
        table: {
          status: 'Status',
          statusCodes: {
            partial_processed: 'Partial processed',
            processing: 'Processing',
            processed: 'Processed',
            failed: 'Failed',
          },
          batch: 'Batch',
          date: 'Submission Date',
          dateAt: 'at',
          actions: 'Actions',
          actionsCodes: {
            viewDetails: 'View details',
          },
        },
      },
      cards: {
        table: {
          status: 'Status',
          quantity: 'Quantity',
          date: 'Request date',
          id: 'Identifier',
        },
        status: {
          [CardBatchStatusEnum.ERROR]: 'Error',
          [CardBatchStatusEnum.PROCESSED]: 'Processed',
          [CardBatchStatusEnum.PROCESSING]: 'Processing',
          [CardBatchStatusEnum.ABORTED]: 'Aborted',
          [CardBatchStatusEnum.PENDING]: 'Pending',
        },
      },
      recharge: {
        table: {
          status: 'Status',
          registerDate: 'Registration date',
          amount: 'Amount',
          distribuition: 'Distribuition',
          distribuitionTypes: {
            manual: 'Manual',
            instantaneous: 'Instantaneous',
            scheduled: 'Scheduled to',
          },
          actions: {
            title: 'Actions',
            viewDetails: 'View details',
            payOrder: 'Pay order',
            releaseOrder: 'Release order',
          },
        },
        status: {
          approved: 'Approved',
          partial: 'Partial approved',
          ready: 'Ready to release',
          pending: 'Pending payment',
          canceled: 'Canceled',
          failed: 'Failed release',
          processing: 'Processing order',
          processingSpreadsheet: 'Processing spreadsheet',
          spreadsheetFailure: 'Processing failure',
          unknown: 'Unknown error',
        },
      },
      companies: {
        table: {
          social: 'Social name',
          document: 'Document',
          email: 'Email',
          actions: {
            title: 'Actions',
            details: 'Details',
            access: 'Access',
          },
        },
      },
    },
    screen: {
      toLogin: 'To log in to the platform,',
      useDesktop: 'look for a desktop device.',
      goBack: 'Back',
    },
    pageHeader: {
      goBack: 'Go back',
    },
    version: {
      qa: 'Portal Benefícios QA - {{front}}/v{{back}}',
      prod: 'Portal Benefícios - {{front}}/v{{back}}',
    },
    dialogs: {
      rhAccess: {
        title: 'Access RH Portal',
        button: {
          cancel: 'Cancel',
          access: 'Access',
        },
        table: {
          identifier: 'Identifier',
          legalName: 'Social name',
        },
      },
    },
    orderSummary: {
      title: 'Order Summary',
      registeredCollaborators: 'Collaborators Registered in the Order',
      totalBalance: 'Total Recharge Amount',
      food_meal: 'Food Assistance',
      food: 'Food',
      meal: 'Meal',
      culture: 'Culture',
      fuel: 'Fuel',
      transport: 'Transport',
      mobility: 'Mobility',
      travel: 'Travel',
      recreation: 'Recreation',
      education: 'Education',
      health: 'Health',
      home_office: 'Home Office',
      general: 'Free Balance',
    },
  },
};
