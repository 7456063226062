import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useEmployeeServiceEmployeeControllerGetEmployeesSheetViaEmail } from '~/api/queries';
import { setError } from '~/redux/reducers/application';

import { IEmailState, IEmployeeEmaiLSheetHook } from '../employee-list.types';

const INITIAL_STATE = {
  isSendEmailModalVisible: false,
  isSendEmailLoading: false,
  isSuccessModalVisible: false,
  isSendEmailHookEnabled: false,
  isSendEmailEnabled: false,
};

export const useEmployeesEmailSheetHook = (): IEmployeeEmaiLSheetHook => {
  const dispatch = useDispatch();
  const [state, setState] = useState<IEmailState>(INITIAL_STATE);

  const { isSendEmailEnabled, isSendEmailHookEnabled, isSendEmailLoading } = state;

  const onSendEmailQuery = useEmployeeServiceEmployeeControllerGetEmployeesSheetViaEmail(undefined, {
    enabled: state.isSendEmailHookEnabled,
  });

  useEffect(() => {
    const handleRefetch = async () => {
      await onSendEmailQuery.refetch().then(() => {
        if (onSendEmailQuery.isSuccess) {
          onChangeEmail({ isSendEmailModalVisible: false });
        }
        onChangeEmail({ isSendEmailLoading: false });
      });
    };

    if (isSendEmailEnabled && isSendEmailHookEnabled) {
      handleRefetch();
      onChangeEmail({ isSendEmailEnabled: false });
    }
  }, [isSendEmailLoading]);

  useEffect(() => {
    if (onSendEmailQuery.isError) {
      dispatch(setError('generic_error'));
      onChangeEmail({ isSendEmailModalVisible: false });
    }
    if (onSendEmailQuery.isSuccess)
      onChangeEmail({ isSuccessModalVisible: true, isSendEmailModalVisible: false });

    onChangeEmail({
      isSendEmailLoading: onSendEmailQuery.isLoading,
      isSendEmailHookEnabled: false,
    });
  }, [onSendEmailQuery.isError, onSendEmailQuery.isSuccess, onSendEmailQuery.isLoading]);

  const onSendEmail = () => {
    onChangeEmail({ isSendEmailLoading: true });
    if (!state.isSendEmailHookEnabled) {
      onChangeEmail({
        isSendEmailHookEnabled: true,
        isSendEmailLoading: false,
      });

      return;
    }
    onChangeEmail({ isSendEmailEnabled: true });
  };

  const onChangeEmail = (state: IEmailState) => {
    setState((prev) => ({ ...prev, ...state }));
  };

  return {
    state,
    onSendEmail,
    onChangeEmail,
    onResetState: () => setState(INITIAL_STATE),
  };
};
