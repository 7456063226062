import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;

    margin:0;
    padding:0;

    position: relative;
  }

  button {
    cursor: pointer;    
  }

  div, span, section, header, footer, main, nav {
    flex-direction: row;
  }
`;

export const Fragment = styled.div`
  width: 100%;
  display: flex;
`;

export const FragmentRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const FragmentCol = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
