import { IconographyNames } from '@vizir-banking/design-system';
import {
  Button as BaseButton,
  ButtonSizes,
  ButtonTypes,
  Icon as BaseIcon,
  Link as BaseLink,
  Skeleton as BaseSkeleton,
  SkeletonVariants,
  Typography,
  TypographyVariants,
} from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import { getColor } from '~/styles/theme/get-theme';

export const PreviousStep = styled(Typography).attrs({
  variant: TypographyVariants.PARAGRAPH_XS,
})`
  display: inline;
  text-decoration: none;
  color: ${getColor('neutral', 'darkest')};
`;

export const Paragraph = styled(Typography).attrs({
  variant: TypographyVariants.PARAGRAPH_XS,
})`
  display: inline;
  color: ${getColor('neutral', 'darkest')};
`;

export const CurrentStep = styled(Typography).attrs({
  variant: TypographyVariants.PARAGRAPH_XS,
})`
  display: inline;
  color: ${getColor('primary', 'regular')};
`;

export const Title = styled(Typography).attrs({
  variant: TypographyVariants.HEADING_XL,
})`
  color: ${getColor('neutral', 'darkest')};
`;

export const Message = styled(Typography).attrs({
  variant: TypographyVariants.DIALOG,
})`
  color: ${getColor('neutral', 'darkest')};
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;
`;

export const PrimaryButton = styled(BaseButton).attrs(({ leadingIconName, trailingIconName }) => ({
  buttonType: ButtonTypes.primary,
  buttonSize: ButtonSizes.medium,
  withLeadingIcon: !!leadingIconName,
  withTrailingIcon: !!trailingIconName,
  leadingIconColor: getColor('neutral', 'darkest'),
  trailingIconColor: getColor('neutral', 'darkest'),
}))``;

export const GoBack = styled(BaseLink).attrs({
  variant: TypographyVariants.PARAGRAPH_S,
})`
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${getColor('neutral', 'darkest')};
`;

export const ArrowLeft = styled(BaseIcon).attrs({
  iconName: IconographyNames.leftArrow,
  color: getColor('neutral', 'darkest'),
})`
  width: 24px;
  height: 24px;
`;

export const Subtitle = styled(Typography).attrs({
  variant: TypographyVariants.HEADING_L,
})`
  color: ${getColor('neutral', 'darkest')};
`;

export const Skeleton = styled(BaseSkeleton).attrs({
  variant: SkeletonVariants.textField,
})``;
