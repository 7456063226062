import { IconographyNames } from '@vizir-banking/design-system';
import { Tabs } from '@vizir-banking/design-system/web';
import { ReactElement } from 'react';

import { ScopeEnum } from '~/api/constants';
import { SimplifiedEmployers } from '~/api/requests';
import { ISSUER_SCREENS_DEFINITIONS } from '~/app/routes/screen-definitions/issuer-portal';
import { DynamicTable } from '~/components/tables/table.layout';
import { ActionsTable } from '~/components/tables/types';
import { useTranslation } from '~/translates/use-translate';

import { Container, PageHeader, Screen, Search } from './companies-list.styles';
import { ICombinedCompanyHook } from './companies-list.types';

export const CompanyListLayout = ({
  tab,
  data,
  document,
  pagination,
  companies,
  isArchiving,
  isLoading,
  onAchievementPress,
  onChangeDocument,
  onChangeTab,
  onSelectCompany,
  onSelectAll,
  onSelectClick,
  onAccessPortal,
  onNavigateTo,
}: ICombinedCompanyHook): ReactElement => {
  const translate = useTranslation('pages.issuer.company.list');
  const tabs = ['active', 'archived'];

  const { onReachEndLoading } = pagination;

  const renderPageHeader = () => {
    return (
      <PageHeader
        trailingButton={{
          label: translate('pageHeader.trailingButton.label'),
          onClick: () => onNavigateTo(ISSUER_SCREENS_DEFINITIONS.new),
          leadingIconName: IconographyNames.add,
        }}
        leadingText={translate('pageHeader.currentStep')}
        title={translate('pageHeader.subtitle')}
        verifyScope={{
          scope: ScopeEnum.CREATE_EMPLOYER,
        }}
      />
    );
  };

  const renderCompaniesList = () => (
    <DynamicTable<SimplifiedEmployers>
      data={data ?? []}
      columns={['status', 'legalName', 'document', 'email']}
      isLoading={isLoading}
      isReachEndLoading={onReachEndLoading}
      actions={[
        {
          label: ActionsTable.VIEW_DETAILS,
          onClick: async (row) => onSelectCompany(row),
        },
        {
          label: ActionsTable.ACCESS_PORTAL,
          onClick: (row) => onAccessPortal(row),
          hasLoading: true,
        },
      ]}
      onRowClick={(row) => onSelectCompany(row)}
      isSelect
      selectedEntities={companies}
      onSelectAll={onSelectAll}
      onSelectClick={onSelectClick}
      isSelectLoading={isArchiving}
      onAchievementPress={onAchievementPress}
      currentTab={tab}
    />
  );

  const renderContent = (key: number) => {
    const content: { [key: number]: ReactElement } = {
      0: renderCompaniesList(),
      1: renderCompaniesList(),
    };

    return content[key];
  };

  const renderItems = tabs.map((tab, index) => ({
    label: translate(`tabs.${tab}`),
    element: renderContent(index),
  }));

  const renderTabs = () => {
    return <Tabs items={renderItems} activeTabIndex={tab} onChange={onChangeTab} />;
  };

  return (
    <Screen>
      <Container>
        {renderPageHeader()}
        <Search
          onChange={onChangeDocument}
          onClear={() => onChangeDocument('')}
          placeholder={translate('search.placeholder')}
          value={document.value ?? ''}
          error={document.error}
        />
      </Container>
      {renderTabs()}
    </Screen>
  );
};
