import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { CreateOrderBodyDto, OrderDetails } from '~/api/requests';

import { BenefitsState } from '../types';

type NewRechargeState = {
  details?: OrderDetails;
  order: CreateOrderBodyDto[];
};

const initialState: NewRechargeState = {
  order: [],
};

const newRechargeSlice = createSlice({
  name: 'newRecharge',
  initialState,
  reducers: {
    setDetails: (state, action: PayloadAction<OrderDetails>) => {
      state.details = action.payload;
    },
    clearDetails: (state) => {
      state.details = undefined;
    },
    setOrder: (state, action: PayloadAction<CreateOrderBodyDto[]>) => {
      state.order = action.payload;
    },
    clearOrder: (state) => {
      state.order = [];
    },
  },
});

export const { setDetails, clearDetails, setOrder, clearOrder } = newRechargeSlice.actions;

export const selectOrderDetails = (state: BenefitsState) => state.newRecharge.details;
export const selectOrder = (state: BenefitsState) => state.newRecharge.order;

export default newRechargeSlice.reducer;
