import { Button, ButtonSizes, ButtonTypes, Checkbox, Table } from '@vizir-banking/design-system/web';
import { noop } from 'lodash';
import { MouseEvent, ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { GetSpecificBatchDetailsResponseDto } from '~/api/requests';
import { useTranslation } from '~/translates/use-translate';

import { Badge } from '../badge/badge';
import { BadgeTypes } from '../badge/types';
import { DropdownProvider } from '../dropdown/dropdown-context';
import { Row, SelectAllText } from './table.styles';
import { ActionsTable, SelectType, TableTypes } from './types';
import { DynamicTableLayout } from './variants/dynamic-table.layout';
import { ProposalsTableLayout } from './variants/proposals-table.layout';

type ExtractColumns<T> = (keyof T)[];

interface IActions<T> {
  label: ActionsTable;
  hasLoading?: boolean;
  onCondition?: (row: T) => boolean;
  onClick: (row: T) => Promise<void>;
}

interface IDynamicTable<T> {
  data: T[] | GetSpecificBatchDetailsResponseDto;
  columns: ExtractColumns<T>;
  type?: TableTypes;
  actions?: IActions<T>[];
  isLoading?: boolean;
  isReachEndLoading?: boolean;
  onRowClick?: (row: T) => void;
  isOrderTable?: boolean;
  isSelect?: boolean;
  isSelectLoading?: boolean;
  selectedEntities?: SelectType<T>[];
  onAchievementPress?: () => void;
  onSelectAll?: () => void;
  onSelectClick?: (e: MouseEvent<HTMLDivElement>, row: T, index: number) => void;
  currentTab?: number;
  hasOrderColumn?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DynamicTable = <T extends Record<string, any>>({
  data,
  columns,
  actions,
  isLoading,
  isReachEndLoading,
  type = TableTypes.DYNAMIC,
  onRowClick,
  isOrderTable = false,
  isSelect = false,
  isSelectLoading = false,
  selectedEntities = [],
  onSelectAll = noop,
  onAchievementPress = noop,
  onSelectClick,
  currentTab,
  hasOrderColumn = false,
}: IDynamicTable<T>) => {
  const palette = useTheme().designSystem.palette;
  const translate = useTranslation('pages.rh.employees.view');

  const renderDynamicTableLayout = () => (
    <DynamicTableLayout<T>
      data={data as T[]}
      columns={columns}
      actions={actions}
      isLoading={isLoading}
      isReachEndLoading={isReachEndLoading}
      onRowClick={onRowClick}
      isOrderTable={isOrderTable}
      isSelect={isSelect}
      selectedEntities={selectedEntities}
      onSelectClick={onSelectClick}
      hasOrderColumn={hasOrderColumn}
    />
  );

  const renderProposalsTableLayout = () => (
    <ProposalsTableLayout data={data as GetSpecificBatchDetailsResponseDto} isLoading={isLoading} />
  );

  const renderTableType = () => {
    const types: { [key: string]: ReactElement } = {
      [TableTypes.DYNAMIC]: renderDynamicTableLayout(),
      [TableTypes.PROPOSALS]: renderProposalsTableLayout(),
    };

    return types[type];
  };

  return (
    <DropdownProvider>
      {isSelect && (
        <Row>
          <Checkbox
            onClick={onSelectAll}
            isIndeterminate={selectedEntities.length === Object.values(data).length && !isLoading}
          />
          <SelectAllText>{translate('select.checkbox')}</SelectAllText>
          <Badge number={selectedEntities.length} type={BadgeTypes.primary} />
          <Button
            buttonSize={ButtonSizes.small}
            buttonType={ButtonTypes.primary}
            containerProps={{ style: { color: palette.neutral.lightest } }}
            label={translate(`select.button.${Boolean(currentTab) ? 'unarchive' : 'archive'}`)}
            onClick={onAchievementPress}
            isLoading={isLoading || isSelectLoading}
          />
        </Row>
      )}
      <Table>{renderTableType()}</Table>
    </DropdownProvider>
  );
};
