import { useQueryClient } from '@tanstack/react-query';
import { isCNPJ } from 'brazilian-values';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useEmployerServiceEmployerControllerListEmployees } from '~/api/queries';
import { SimplifiedEmployers } from '~/api/requests';
import { ISSUER_SCREENS_DEFINITIONS } from '~/app/routes/screen-definitions/issuer-portal';
import { useOnReachEnd } from '~/hooks/use-reach-end';
import { unflatten } from '~/utils/unformat-value';

import { DocumentState, IListCompaniesHook, PaginationState } from '../companies-list.types';

const COMPANIES_PER_PAGE = 15;

const INITIAL_DOCUMENT_STATE = {
  value: '',
  error: '',
};

const INITIAL_PAGINATION_STATE = {
  page: 1,
  isEnabled: true,
  onReachEndLoading: false,
};

export const useListCompaniesHook = (): IListCompaniesHook => {
  const queryClient = useQueryClient();
  const navigation = useNavigate();

  const [document, setDocument] = useState<DocumentState>(INITIAL_DOCUMENT_STATE);
  const [data, setData] = useState<SimplifiedEmployers[]>([]);
  const [pagination, setPagination] = useState<PaginationState>(INITIAL_PAGINATION_STATE);
  const [tab, setTab] = useState(0);

  const getDocument = useCallback(() => {
    return document.value.length === 18 && !document.error ? unflatten(document.value) : undefined;
  }, [document.value]);

  const {
    data: companies,
    isPending: isCompaniesPending,
    isRefetching: isCompaniesRefetching,
    refetch: onRefetchCompanies,
  } = useEmployerServiceEmployerControllerListEmployees(
    {
      page: pagination.page,
      perPage: COMPANIES_PER_PAGE,
      document: getDocument(),
      isArchived: !!tab,
    },
    ['companies', pagination.page, document.value, tab],
    { enabled: pagination.isEnabled }
  );

  useOnReachEnd(() => {
    const { onReachEndLoading, isEnabled } = pagination;
    if (!onReachEndLoading && !isCompaniesPending && data.length > 0 && isEnabled) {
      setPagination((prev) => ({ ...prev, page: prev.page + 1, onReachEndLoading: true }));
    }
  });

  useEffect(() => {
    const isValidLength = document.value.length === 18;
    const isValidDocument = isValidLength && isCNPJ(document.value);

    if (document.value.length === 0) {
      setPagination((prev) => ({ ...prev, isEnabled: true }));
      setDocument((prev) => ({ ...prev, error: '' }));
    } else {
      setPagination((prev) => ({ ...prev, isEnabled: isValidDocument }));

      if (isValidLength && !isValidDocument) {
        setDocument((prev) => ({ ...prev, error: 'CNPJ Inválido' }));
      } else {
        setDocument((prev) => ({ ...prev, error: '' }));
      }
    }
  }, [document.value, tab]);

  useEffect(() => {
    if (!companies) return;
    const { data: newCompaniesData } = companies;
    const isFirstPage = pagination.page === 1;

    setData((prevCompanies) => {
      if (isFirstPage) return newCompaniesData;
      const filteredCompanies = prevCompanies.filter(
        (company) => !newCompaniesData.some((newCompany) => newCompany.personId === company.personId)
      );

      return [...filteredCompanies, ...newCompaniesData];
    });

    setPagination((prev) => {
      if (!prev.isEnabled && newCompaniesData.length < COMPANIES_PER_PAGE) return prev;
      return {
        ...prev,
        onReachEndLoading: false,
        isEnabled: newCompaniesData.length >= COMPANIES_PER_PAGE,
      };
    });

    if (newCompaniesData.length === 0 && document.value.length === 18) {
      setDocument((prev) => (prev.error ? prev : { ...prev, error: 'CNPJ inválido' }));
    }
  }, [companies, pagination.page, document.value]);

  useEffect(() => {
    onRefetch();
  }, [tab]);

  const onRefetch = async () => {
    setPagination(INITIAL_PAGINATION_STATE);
    setData([]);
    await queryClient.resetQueries();
    await onRefetchCompanies();
  };

  const onSelectCompany = (company: SimplifiedEmployers) => {
    navigation(ISSUER_SCREENS_DEFINITIONS.details, {
      state: { company },
    });
  };

  const onChangeDocument = useCallback((document: string) => {
    setDocument({ value: document, error: '' });
    const isValidDocument = document.length === 18 && isCNPJ(document);
    if (isValidDocument) {
      setData([]);
      setPagination(INITIAL_PAGINATION_STATE);
    }
  }, []);

  return {
    tab,
    data,
    document,
    pagination,
    isLoading: (isCompaniesPending && pagination.page === 1 && pagination.isEnabled) || isCompaniesRefetching,
    onChangeDocument,
    onChangeTab: setTab,
    onRefetchCompanies: onRefetch,
    onSelectCompany,
  };
};
