import { useNavigate } from 'react-router-dom';

import { ICombinedCompanyHook } from './companies-list.types';
import { useArchiveCompanyHook } from './hooks/use-archive-company.hook';
import { useListCompaniesHook } from './hooks/use-list-companies.hook';
import { useSwitchRoleHook } from './hooks/use-switch-role.hook';

export const useCompanyListHook = (): ICombinedCompanyHook => {
  const navigation = useNavigate();

  const {
    tab,
    data,
    document,
    pagination,
    isLoading,
    onChangeDocument,
    onChangeTab,
    onRefetchCompanies,
    onSelectCompany,
  } = useListCompaniesHook();
  const { onAccessPortal } = useSwitchRoleHook();
  const { companies, isArchiving, onAchievementPress, onSelectAll, onSelectClick } = useArchiveCompanyHook({
    tab,
    data,
    onRefetch: onRefetchCompanies,
  });

  const onNavigateTo = (path: string) => navigation(path);

  return {
    tab,
    data,
    document,
    pagination,
    companies,
    isArchiving,
    isLoading,
    onAchievementPress,
    onChangeDocument,
    onChangeTab,
    onRefetchCompanies,
    onSelectCompany,
    onSelectAll,
    onSelectClick,
    onAccessPortal,
    onNavigateTo,
  };
};
