import { MouseEvent, useEffect, useState } from 'react';

import { useEmployerServiceEmployerControllerUpdateEmployeeStatus } from '~/api/queries';
import { SimplifiedEmployers } from '~/api/requests';
import { SelectType } from '~/components/tables/types';

import { IArchiveCompanyHook, IArchiveCompanyProperties } from '../companies-list.types';

export const useArchiveCompanyHook = ({
  data,
  tab,
  onRefetch,
}: IArchiveCompanyProperties): IArchiveCompanyHook => {
  const [companies, setCompanies] = useState<SelectType<SimplifiedEmployers>[]>([]);

  const onArchiveCompanyQuery = useEmployerServiceEmployerControllerUpdateEmployeeStatus({
    onSuccess: async () => {
      setCompanies([]);
      await onRefetch();
    },
  });

  useEffect(() => setCompanies([]), [tab]);

  const onArchiveCompany = () => {
    onArchiveCompanyQuery.mutateAsync({
      requestBody: {
        employers: companies.map((company) => ({
          document: company.row.document ?? '',
          isArchived: !tab,
        })),
      },
    });
  };

  const onAchievementPress = () => {
    if (!companies?.length) return;
    onArchiveCompany();
  };

  const onSelectAll = () => {
    if (companies.length === data.length) {
      return setCompanies([]);
    }
    setCompanies(Object.values(data).map((row, index) => ({ index, row, value: true })));
  };

  const onSelectClick = (e: MouseEvent<HTMLDivElement>, row: SimplifiedEmployers, index: number) => {
    e.stopPropagation();

    setCompanies((prevSelectedCompanies) => {
      if (prevSelectedCompanies.find((selected) => selected.index === index)) {
        return prevSelectedCompanies.filter((selected) => selected.index !== index);
      }
      return [...prevSelectedCompanies, { index, row, value: true }];
    });
  };

  return {
    companies,
    isArchiving: onArchiveCompanyQuery.isPending,
    onAchievementPress,
    onSelectAll,
    onSelectClick,
  };
};
