import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useAuthServiceAuthControllerLoginBySwithRole } from '~/api/queries';
import { OpenAPI, SimplifiedEmployers } from '~/api/requests';
import { RH_SCREENS_DEFINITIONS } from '~/app/routes/screen-definitions/rh-portal';
import { setIsRh, setIsSwitchingPortals } from '~/redux/reducers/portal';
import { setIssuerToken, setToken } from '~/redux/reducers/user';

import { ISwitchRoleHook } from '../companies-list.types';

export const useSwitchRoleHook = (): ISwitchRoleHook => {
  const navigation = useNavigate();
  const dispatch = useDispatch();

  const onSwitchRole = useAuthServiceAuthControllerLoginBySwithRole({
    onSuccess: async (session) => {
      dispatch(setIsSwitchingPortals(true));

      await Promise.all([
        dispatch(setIssuerToken()),
        dispatch(setIsRh(true)),
        dispatch(setToken(session.token)),
      ]);

      OpenAPI.HEADERS = {
        ...OpenAPI.HEADERS,
        Authorization: `Bearer ${session.token}`,
      };

      navigation(RH_SCREENS_DEFINITIONS.rhHome);
    },
  });

  const onAccessPortal = async (company: SimplifiedEmployers) => {
    await onSwitchRole.mutateAsync({
      requestBody: {
        company: company.personId,
      },
    });
  };

  return {
    onAccessPortal,
  };
};
