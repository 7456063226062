import React, { ReactElement } from 'react';

import { Number, Wrapper } from './badge.styles';
import { Props } from './types';

export const Badge = ({ type, number }: Props): ReactElement => {
  const handleNumber = () => {
    if (number < 10) return `0${number}`;
    return String(number);
  };

  return (
    <Wrapper type={type}>
      <Number>{handleNumber()}</Number>
    </Wrapper>
  );
};
