import { formatToCEP, formatToCNPJ } from 'brazilian-values';
import { noop } from 'lodash';
import { useState } from 'react';

import { StatusEmployerEnum } from '~/api/constants';
import { ErrorDialog } from '~/components/dialogs/error-dialog/error-dialog';
import { SuccessDialog } from '~/components/dialogs/success-dialog/success-dialog';
import { FragmentCol } from '~/styles/global-styles';
import { useTranslation } from '~/translates/use-translate';

import {
  Box,
  BoxInfoLabel,
  BoxInfoValue,
  BoxInfoWrapper,
  BoxSkeleton,
  BoxTitle,
  BoxWrapper,
  ButtonRow,
  ButtonWithIcons,
  EditIcon,
  Link,
  PageHeader,
  PrimaryButton,
  Row,
  Screen,
  TertiaryButton,
  TextField,
  TextFieldSkeleton,
  TextFieldWrapper,
} from './company-details.styles';
import { BoxType, ICompanyDetails } from './company-details.types';

export const CompanyDetailsLayout = ({
  company,
  isLoading,
  isUpdateLoading,
  isGetAddressInfoLoading,
  isContactEditMode,
  isAddressEditMode,
  address,
  contact,
  errors,
  closeModalProps,
  onEdit,
  isButtonDisabled,
  onSubmit,
  handleData,
  goBack,
  onAccessPortal,
  isSwitchActive,
  toggleSwitch,
}: ICompanyDetails) => {
  const translate = useTranslation('pages.issuer.company.details');

  const [isAccessPortalLoading, setIsAccessPortalLoading] = useState(false);

  const renderEditIcon = (onClick: () => void) => {
    return (
      <Link onClick={isLoading ? noop : onClick}>
        <EditIcon />
      </Link>
    );
  };

  const renderPageHeader = () => (
    <>
      <PageHeader
        isLoading={isLoading}
        title={company?.legalName ?? ''}
        leadingGoBack={goBack}
        trailingSwitch={{
          title: translate('pageHeader.status.title'),
          message: isSwitchActive
            ? translate('pageHeader.status.active')
            : translate('pageHeader.status.archived'),
          isActive: isSwitchActive,
          onChange: toggleSwitch,
        }}
      />
      <ButtonWithIcons
        label={translate('pageHeader.trailingButton.label')}
        onClick={async () => {
          setIsAccessPortalLoading(true);
          await onAccessPortal({
            personId: company?.personId ?? '',
            status: StatusEmployerEnum.ACTIVE,
          }).finally(() => setIsAccessPortalLoading(false));
        }}
        isLoading={isLoading || isAccessPortalLoading}
      />
    </>
  );

  const renderBoxTitle = (box: string) => {
    return <BoxTitle>{translate(`${box}.title`)}</BoxTitle>;
  };

  const renderTextField = (box: BoxType, name: string) => {
    const loadingTextFields = ['street', 'neighborhood', 'city', 'state', 'country'];
    const disabledTextFields = ['city', 'state', 'country'];
    const isZipCode = name === 'zipCode';
    const value = {
      contact: contact?.[name],
      // @ts-expect-error - name is AddressDto key
      address: address?.[name],
    };

    if (isZipCode) value[box] = formatToCEP(value[box] ?? '');

    const maxLength: { [key: string]: number } = {
      phoneNumber: 15,
      zipCode: 9,
    };

    if (loadingTextFields.includes(name) && isGetAddressInfoLoading) {
      return <TextFieldSkeleton />;
    }

    return (
      <TextFieldWrapper>
        <TextField
          label={translate(`${box}.fields.${name}`)}
          onChange={(value: string) => handleData(box, name, value)}
          onClearTextField={() => handleData(box, name, undefined)}
          value={value[box] ?? ''}
          error={errors?.[name]}
          maxLength={maxLength?.[name]}
          isDisabled={disabledTextFields.includes(name)}
        />
      </TextFieldWrapper>
    );
  };

  const renderButton = (box: BoxType, onCancel: () => void, onConfirm: () => Promise<void>) => {
    return (
      <ButtonRow>
        <TertiaryButton label='Cancelar' onClick={onCancel} />
        <PrimaryButton
          label='Confirmar'
          onClick={onConfirm}
          isLoading={isUpdateLoading}
          isDisabled={isButtonDisabled(box)}
        />
      </ButtonRow>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderInfo = (box: string, object: any, type: string) => {
    let value = object?.[type];
    const isCNPJ = type === 'document';
    const isCreatedAt = type === 'createdAt';

    if (isCNPJ) {
      value = formatToCNPJ(object?.[type] ?? '');
    }
    if (isCreatedAt) {
      value = new Date(object?.[type]).toLocaleDateString('pt-BR');
    }

    return (
      <BoxInfoWrapper key={type}>
        {isLoading ? (
          <BoxSkeleton />
        ) : (
          <FragmentCol style={{ gap: 8 }}>
            <BoxInfoLabel>{translate(`${box}.fields.${type}`)}</BoxInfoLabel>
            <BoxInfoValue>{value ?? '-'}</BoxInfoValue>
          </FragmentCol>
        )}
      </BoxInfoWrapper>
    );
  };

  const renderInfoBoxContent = () => {
    const fields = ['legalName', 'document'];

    return fields.map((field) => renderInfo('info', company, field));
  };

  const renderContactBoxContent = () => {
    const fields = ['email', 'phoneNumber'];

    return fields.map((field) =>
      isContactEditMode
        ? renderTextField('contact', field)
        : renderInfo(
            'contact',
            { email: company?.email.email, phoneNumber: company?.phone.phoneNumber },
            field
          )
    );
  };

  const renderAddressBoxContent = () => {
    const fields = ['zipCode', 'street', 'number', 'complement', 'neighborhood', 'city', 'state', 'country'];

    return fields.map((field) =>
      isAddressEditMode ? renderTextField('address', field) : renderInfo('address', company?.address, field)
    );
  };

  const renderCompanyInfo = () => {
    return (
      <Box>
        {renderBoxTitle('info')}
        <BoxWrapper>{renderInfoBoxContent()}</BoxWrapper>
      </Box>
    );
  };

  const renderCompanyContact = () => {
    return (
      <Box>
        <Row>
          {renderBoxTitle('contact')}
          {renderEditIcon(() => onEdit('contact'))}
        </Row>
        <BoxWrapper>
          {renderContactBoxContent()}
          {isContactEditMode &&
            renderButton(
              'contact',
              () => onEdit('contact'),
              async () => onSubmit('contact')
            )}
        </BoxWrapper>
      </Box>
    );
  };

  const renderCompanyAddress = () => {
    return (
      <Box>
        <Row>
          {renderBoxTitle('address')}
          {renderEditIcon(() => onEdit('address'))}
        </Row>
        <BoxWrapper>
          {renderAddressBoxContent()}
          {isAddressEditMode &&
            renderButton(
              'address',
              () => onEdit('address'),
              async () => onSubmit('address')
            )}
        </BoxWrapper>
      </Box>
    );
  };

  return (
    <Screen>
      {renderPageHeader()}
      {renderCompanyInfo()}
      {renderCompanyContact()}
      {renderCompanyAddress()}
      <SuccessDialog secondaryButtonProps={closeModalProps} />
      <ErrorDialog />
    </Screen>
  );
};
