import { IconographyNames } from '@vizir-banking/design-system';
import {
  Button as BaseButton,
  ButtonSizes,
  ButtonTypes,
  Dialog as BaseDialog,
  Icon as BaseIcon,
  Link as BaseLink,
  LinkVariants,
  ModalSizes,
  PinInput as BasePinInput,
  Typography,
  TypographyVariants,
} from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import {
  Notification as BaseNotification,
  NotificationLeadingContentType,
} from '~/components/notification/notification';
import { Screen as BaseScreen } from '~/components/screen/screen';
import { ScreenTypes } from '~/components/screen/types';
import { getColor } from '~/styles/theme/get-theme';

export const Screen = styled(BaseScreen).attrs({
  type: ScreenTypes.LOGIN,
})``;

export const Dialog = styled(BaseDialog).attrs({
  isVisible: true,
  size: ModalSizes.medium,
})``;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: end;
`;

export const PinInput = styled(BasePinInput).attrs({
  codeSize: 6,
})``;

export const Emphasys = styled(Typography).attrs({
  variant: TypographyVariants.HEADING_XL,
})`
  margin-top: 48px;
  margin-bottom: 24px;
`;

export const Notification = styled(BaseNotification).attrs(() => ({
  leadingContentType: NotificationLeadingContentType.ICON,
  leadingIcon: {
    iconName: IconographyNames.email,
    iconColor: getColor('neutral', 'darkest'),
  },
  containerProps: {
    style: {
      marginBottom: '24px',
    },
  },
}))``;

export const PinWrapper = styled.div`
  max-width: 460px;
  margin-top: 24px;
  margin-bottom: 40px;
`;

export const Paragraph = styled(Typography).attrs({
  variant: TypographyVariants.PARAGRAPH_XS,
})`
  color: ${({ theme }) => theme.designSystem.palette.neutral.darkest};
  flex-direction: row;
`;

export const Link = styled(BaseLink).attrs({
  linkVariant: LinkVariants.xs,
})`
  display: inline;
  font-weight: 500;
  color: ${({ theme }) => theme.designSystem.palette.primary.regular};
  text-decoration: underline;
`;

export const BottomWrapper = styled.div`
  height: 48px;
  justify-content: center;
  align-items: center;
`;

export const Button = styled(BaseButton).attrs({
  buttonType: ButtonTypes.tertiary,
  buttonSize: ButtonSizes.small,
  withLeadingIcon: true,
  leadingIconName: IconographyNames.reload,
})``;

export const CloseButton = styled(BaseLink)`
  width: 100%;
  justify-content: flex-end;
`;

export const CloseIcon = styled(BaseIcon).attrs({
  iconName: IconographyNames.close,
  color: getColor('neutral', 'darkest'),
})`
  width: 24px;
  height: 24px;
`;
