import { Masks } from '@vizir-banking/design-system/web/components/text-field/types';
import styled from 'styled-components';

import {
  LeadingContentType,
  PageHeader as BasePageHeader,
  TrailingContentType,
} from '~/components/page-header/page-header';
import { Screen as BaseScreen } from '~/components/screen/screen';
import { ScreenPortalTypes, ScreenTypes } from '~/components/screen/types';
import { Search as BaseSearch } from '~/components/search/search';

export const Screen = styled(BaseScreen).attrs({
  type: ScreenTypes.LOGGED,
  portal: ScreenPortalTypes.ISSUER,
})``;

export const Container = styled.div`
  width: 100%;
  flex-direction: column;
  gap: 24px;
  display: flex;
`;

export const PageHeader = styled(BasePageHeader).attrs({
  leadingContentType: LeadingContentType.TEXT,
  trailingContentType: TrailingContentType.BUTTON,
})``;

export const Search = styled(BaseSearch).attrs({
  label: '',
  mask: Masks.cnpj,
})``;
