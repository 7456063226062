import { useQueryClient } from '@tanstack/react-query';
import { isCPF } from 'brazilian-values';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useEmployeeServiceEmployeeControllerListEmployees } from '~/api/queries';
import { SimplifiedEmployees } from '~/api/requests';
import { RH_SCREENS_DEFINITIONS } from '~/app/routes/screen-definitions/rh-portal';
import { useOnReachEnd } from '~/hooks/use-reach-end';
import { useTranslation } from '~/translates/use-translate';
import { unflatten } from '~/utils/unformat-value';

import { DocumentState, IListEmployeesHook, PaginationState } from '../employee-list.types';

const EMPLOYEES_PER_PAGE = 20;

const INITIAL_DOCUMENT_STATE = {
  value: '',
  error: '',
};

const INITIAL_PAGINATION_STATE = {
  page: 1,
  isEnabled: true,
  onReachEndLoading: false,
};

export const useListEmployeesHook = (): IListEmployeesHook => {
  const queryClient = useQueryClient();
  const navigation = useNavigate();
  const translate = useTranslation('pages.rh.employees.view');

  const [document, setDocument] = useState<DocumentState>(INITIAL_DOCUMENT_STATE);
  const [pagination, setPagination] = useState<PaginationState>(INITIAL_PAGINATION_STATE);
  const [data, setData] = useState<SimplifiedEmployees[]>([]);
  const [tab, setTab] = useState(0);

  const getDocument = useCallback(() => {
    return document.value.length === 14 && !document.error ? unflatten(document.value) : undefined;
  }, [document.value]);

  const {
    data: employees,
    isPending: isEmployeesPending,
    isRefetching: isEmployeesRefetching,
    refetch: onRefetchEmployees,
  } = useEmployeeServiceEmployeeControllerListEmployees(
    {
      page: pagination.page,
      perPage: EMPLOYEES_PER_PAGE,
      document: getDocument(),
      isArchived: !!tab,
    },
    ['employees', pagination.page, document.value],
    { enabled: pagination.isEnabled }
  );

  useOnReachEnd(() => {
    const { onReachEndLoading, isEnabled } = pagination;
    if (!onReachEndLoading && !isEmployeesPending && data.length > 0 && isEnabled) {
      setPagination((prev) => ({ ...prev, page: prev.page + 1, onReachEndLoading: true }));
    }
  });

  useEffect(() => {
    const isValidLength = document.value.length === 14;
    const isValidDocument = isValidLength && isCPF(document.value);

    if (document.value.length === 0) {
      setPagination((prev) => ({ ...prev, isEnabled: true }));
      setDocument((prev) => ({ ...prev, error: '' }));
    } else {
      setPagination((prev) => ({ ...prev, isEnabled: isValidDocument }));

      if (isValidLength && !isValidDocument) {
        setDocument((prev) => ({ ...prev, error: translate('error.document') }));
      } else {
        setDocument((prev) => ({ ...prev, error: '' }));
      }
    }
  }, [document.value, tab]);

  useEffect(() => {
    if (!employees) return;
    const { data } = employees;
    const isFirstPage = pagination.page === 1;

    setData((prev) => {
      if (isFirstPage) return data;
      const uniqueEmployees = prev.filter(
        (employee) => !data.some((newEmployee) => newEmployee.personId === employee.personId)
      );
      return [...uniqueEmployees, ...data];
    });
    setPagination((prev) => {
      if (!prev.isEnabled && data.length < EMPLOYEES_PER_PAGE) return prev;
      return {
        ...prev,
        onReachEndLoading: false,
        isEnabled: data.length >= EMPLOYEES_PER_PAGE,
      };
    });

    if (data.length === 0 && document.value.length === 14) {
      setDocument((prev) => (prev.error ? prev : { ...prev, error: translate('error.notFound') }));
    }
  }, [employees, pagination.page, document.value]);

  useEffect(() => {
    onRefetch();
  }, [tab]);

  const onRefetch = async () => {
    setPagination(INITIAL_PAGINATION_STATE);
    setData([]);
    await queryClient.resetQueries();
    await onRefetchEmployees();
  };

  const onChangeDocument = useCallback((document: string) => {
    setDocument({ value: document, error: '' });
    const isValidDocument = document.length === 14 && isCPF(document);
    if (isValidDocument) {
      setPagination(INITIAL_PAGINATION_STATE);
      setData([]);
    }
  }, []);

  const onSelectEmployee = (employee: SimplifiedEmployees) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    navigation(RH_SCREENS_DEFINITIONS.profileEmployee, {
      state: {
        employee: employee,
        index: data.findIndex((e) => e.personId === employee.personId),
      },
    });
  };

  return {
    tab,
    employees: data,
    document,
    pagination,
    isLoading: (isEmployeesPending && pagination.page === 1 && pagination.isEnabled) || isEmployeesRefetching,
    onChangeDocument,
    onChangeTab: setTab,
    onRefetchEmployees: onRefetch,
    onSelectEmployee,
  };
};
