import {
  ButtonProps,
  Switch,
  Tag,
  TagProps,
  TagSizes,
  TagTypes,
  TagVariants,
} from '@vizir-banking/design-system/web';
import { noop } from 'lodash';
import { ReactElement } from 'react';

import { ScopeEnum } from '~/api/requests';
import { useUserScopes } from '~/hooks/use-user-scopes';
import { useTranslation } from '~/translates/use-translate';

import {
  ArrowLeft,
  Column,
  CurrentStep,
  GoBack,
  Message,
  Paragraph,
  PreviousStep,
  PrimaryButton,
  Row,
  Skeleton,
  Subtitle,
  Title,
  Wrapper,
} from './page-header.styles';

export enum TrailingContentType {
  BUTTON = 'button',
  SWITCH = 'switch',
  TAG = 'tag',
  TEXT = 'text',
}

export enum LeadingContentType {
  STEP = 'step',
  TEXT = 'text',
  GO_BACK = 'go_back',
}

type ButtonTypes = Omit<ButtonProps, 'buttonSize' | 'buttonType'>;

interface IPageHeader {
  isLoading?: boolean;
  title: string;
  leadingContentType?: LeadingContentType;
  leadingStep?: {
    previousStep: string;
    currentStep: string;
  };
  leadingText?: string;
  leadingGoBack?: () => void;
  trailingContentType?: TrailingContentType;
  trailingButton?: ButtonTypes;
  trailingSwitch?: {
    title?: string;
    message?: string;
    isActive: boolean;
    onChange: () => void;
  };
  trailingTag?: {
    message?: string;
    tagProps: TagProps;
  };
  trailingText?: string;
  verifyScope?: {
    scope: ScopeEnum | ScopeEnum[];
  };
}

export const PageHeader = ({
  isLoading,
  leadingContentType,
  leadingText,
  leadingGoBack,
  leadingStep,
  title,
  trailingButton,
  trailingContentType,
  trailingSwitch,
  trailingTag,
  trailingText,
  verifyScope,
}: IPageHeader) => {
  const translate = useTranslation('components.pageHeader');
  const { hasScope } = useUserScopes();

  const renderLeading = (): ReactElement | undefined => {
    if (leadingContentType === LeadingContentType.STEP) {
      const { currentStep, previousStep } = leadingStep ?? {};

      return (
        <CurrentStep>
          <PreviousStep>{previousStep}</PreviousStep>
          <Paragraph> &gt;</Paragraph>
          {currentStep}
        </CurrentStep>
      );
    }

    if (leadingContentType === LeadingContentType.TEXT) {
      return <CurrentStep>{leadingText}</CurrentStep>;
    }

    if (leadingContentType === LeadingContentType.GO_BACK && leadingGoBack) {
      return (
        <GoBack onClick={leadingGoBack}>
          <ArrowLeft />
          {translate('goBack')}
        </GoBack>
      );
    }
  };

  const renderTrailing = (): ReactElement | undefined => {
    if (trailingContentType === TrailingContentType.BUTTON) {
      const {
        label = '',
        onClick = noop,
        isDisabled,
        isLoading,
        trailingIconName,
        leadingIconName,
      } = trailingButton ?? {};
      const { scope } = verifyScope ?? {};

      if ((scope && hasScope(scope)) || !scope) {
        return (
          <PrimaryButton
            leadingIconName={leadingIconName}
            trailingIconName={trailingIconName}
            label={label}
            onClick={onClick}
            isDisabled={isDisabled}
            isLoading={isLoading}
          />
        );
      }
    }

    if (trailingContentType === TrailingContentType.SWITCH) {
      const { title, message, isActive = false, onChange = noop } = trailingSwitch ?? {};

      return (
        <Row>
          <Row>
            {title && <Subtitle>{title}</Subtitle>}
            <Message>{message}</Message>
          </Row>
          <Switch isActive={isActive} onChange={onChange} />
        </Row>
      );
    }

    if (trailingContentType === TrailingContentType.TEXT) {
      return <Message>{trailingText}</Message>;
    }

    if (trailingContentType === TrailingContentType.TAG) {
      const tagPropsDefault: TagProps = {
        label: '',
        size: TagSizes.large,
        type: TagTypes.info,
        variant: TagVariants.primary,
      };

      const { message, tagProps = tagPropsDefault } = trailingTag ?? {};

      return (
        <Row>
          <Message>{message}</Message>
          <Tag {...tagProps} />
        </Row>
      );
    }
  };

  const renderTitle = () => {
    return isLoading ? <Skeleton /> : <Title>{title}</Title>;
  };

  return (
    <Wrapper>
      <Column>
        {renderLeading()}
        {renderTitle()}
      </Column>
      {renderTrailing()}
    </Wrapper>
  );
};
