export const translations = {
  search: {
    title: 'Buscar',
    heading: 'Pesquise o CPF que deseja editar ou excluir da planilha',
    button: {
      submit: 'Buscar',
      continue: 'Continuar',
    },
  },
  message: {
    title: 'Atualizado!',
    heading: 'Pedido atualizado com sucesso!',
    description:
      'Certifique-se de que sua carteira tenha crédito suficiente no valor atualizado no dia agendado para a distribuição.',
    nextButtonTitle: 'Finalizar',
  },
  line: {
    title: 'Edição',
    heading: 'Editar pedido em lote',
    nextButtonTitle: 'Confirmar',
    backButtonTitle: 'Voltar',
    noRowsMessage: 'Nenhum dado encontrado',
    discardButtonTitle: 'Excluir linha',
  },
  alerts: {
    confirmClose: {
      headerTitle: 'Sair do fluxo de importação',
      bodyText: 'Você tem certeza? Suas informações atuais não serão salvas.',
      cancelButtonTitle: 'Cancelar',
      exitButtonTitle: 'Sair do fluxo',
    },
    submitIncomplete: {
      headerTitle: 'Erros detectados',
      bodyText: 'Ainda existem algumas linhas que contêm erros. Linhas com erros serão ignoradas ao enviar.',
      bodyTextSubmitForbidden: 'Ainda existem algumas linhas contendo erros.',
      cancelButtonTitle: 'Entendi',
      finishButtonTitle: 'Enviar',
    },
    submitError: {
      title: 'Erro',
      defaultMessage: 'Ocorreu um erro ao enviar os dados',
    },
    unmatchedRequiredFields: {
      headerTitle: 'Nem todas as colunas foram correspondidas',
      bodyText: 'Existem colunas obrigatórias que não foram correspondidas ou ignoradas.',
      listTitle: 'Colunas não correspondidas:',
      cancelButtonTitle: 'Entendi',
      continueButtonTitle: 'Continuar',
    },
    toast: {
      error: 'Erro',
    },
  },
};

export const enUs = {};
