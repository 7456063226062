import { Typography, TypographyVariants } from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import { getColor } from '~/styles/theme/get-theme';

import { BadgeTypes } from './types';

const getBadgeColor = (type: BadgeTypes) => {
  const colors = {
    [BadgeTypes.primary]: getColor('primary', 'regular'),
    [BadgeTypes.info]: getColor('info', 'medium'),
    [BadgeTypes.success]: getColor('success', 'medium'),
    [BadgeTypes.error]: getColor('error', 'medium'),
    [BadgeTypes.warning]: getColor('warning', 'medium'),
  };
  return colors[type] || getColor('neutral', 'medium');
};

export const Wrapper = styled.div<{ type: BadgeTypes }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  border-radius: 8px;
  background-color: ${(props) => getBadgeColor(props.type)};
`;

export const Number = styled(Typography).attrs({
  variant: TypographyVariants.CAPTION,
})`
  color: ${getColor('neutral', 'lightest')};
  font-weight: 600;
`;
