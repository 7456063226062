import { useQueryClient } from '@tanstack/react-query';
import { formatToCPF } from 'brazilian-values';
import { useEffect, useState } from 'react';

import {
  useLoadOrderServiceOrderControllerGetOrderItems,
  useLoadOrderServiceOrderControllerUpdateItems,
} from '~/api/queries';
import {
  ColumnDto,
  CreateOrderBodyDto,
  GetOrderItemsResponseDto,
  OrderItem,
  UpdateOrderItemDto,
} from '~/api/requests';
import { unflatten } from '~/utils/unformat-value';

interface IOrderEditHook {
  order?: GetOrderItemsResponseDto;
  document: string;
  isLoading: boolean;
  onChangeDocument: (value: string) => void;
  onSearchDocument: () => void;
  onSubmit: (data: CreateOrderBodyDto[], isDeleting?: boolean) => Promise<void>;
}

interface IOrderEdit {
  id: string;
  fields: ColumnDto[];
}

export const useOrderEditHook = ({ id, fields }: IOrderEdit): IOrderEditHook => {
  const queryClient = useQueryClient();

  const [document, setDocument] = useState('');
  const [isEnabled, setIsEnabled] = useState(false);
  const [data, setData] = useState<OrderItem[]>([]);

  const {
    data: order,
    isLoading: isOrderPending,
    isSuccess: isOrderSuccess,
  } = useLoadOrderServiceOrderControllerGetOrderItems({ cpf: unflatten(document), id }, undefined, {
    enabled: isEnabled,
    staleTime: 0,
  });

  const onUpdateOrder = useLoadOrderServiceOrderControllerUpdateItems({
    onSuccess: async () => {
      queryClient.removeQueries();
      queryClient.invalidateQueries();
      queryClient.resetQueries();
      setData([]);
    },
  });

  useEffect(() => {
    if (order) {
      setData(order.items);
    }
  }, [order]);

  useEffect(() => {
    if (isOrderSuccess) {
      setIsEnabled(false);
      setDocument('');
    }
  }, [isOrderSuccess]);

  const onChangeDocument = (value: string) => {
    setIsEnabled(false);
    setDocument(formatToCPF(value));
  };

  const onSearchDocument = () => {
    setIsEnabled(true);
  };

  const onSubmitEdit = async (values: CreateOrderBodyDto[], isDeleting?: boolean) => {
    const updatedItems: UpdateOrderItemDto[] = fields
      .map((category) => {
        const amount = isDeleting ? 0 : values[0][category.label as keyof CreateOrderBodyDto];

        // TODO: homeoffice is a temporary fix
        const item = data.find(
          (i) =>
            i.balanceCategory ===
            (category.originalKey === 'homeoffice' ? 'home_office' : category.originalKey)
        );

        if (item) {
          return { id: item.id, amount: Number(amount) };
        }

        return null;
      })
      .filter((item): item is UpdateOrderItemDto => item !== null);

    await onUpdateOrder.mutateAsync({
      id,
      requestBody: {
        items: updatedItems,
      },
    });
  };

  return {
    order,
    document,
    isLoading: isOrderPending || onUpdateOrder.isPending,
    onChangeDocument,
    onSearchDocument,
    onSubmit: onSubmitEdit,
  };
};
