export enum BadgeTypes {
  primary = 'primary',
  info = 'info',
  success = 'success',
  error = 'error',
  warning = 'warning',
}

export type Props = {
  type: BadgeTypes;
  number: number;
};
