import { useEmployerServiceEmployerControllerGetEmployerAddresses } from '~/api/queries';
import { AddressDto } from '~/api/requests';

interface INewCardsAddressHook {
  data: AddressDto[];
  isGetAddressLoading: boolean;
  isButtonDisabled: boolean;
  onSubmit: () => void;
}

interface INewCards {
  address: AddressDto;
  onChangeTab: (tab: number) => void;
}

export const useNewCardsAddressHook = ({ address, onChangeTab }: INewCards): INewCardsAddressHook => {
  const getAddress = useEmployerServiceEmployerControllerGetEmployerAddresses();

  const isButtonDisabled = () => !address.id;

  const onSubmit = () => {
    onChangeTab(2);
  };

  return {
    data: getAddress.data || [],
    isGetAddressLoading: getAddress.isLoading,
    isButtonDisabled: isButtonDisabled(),
    onSubmit,
  };
};
