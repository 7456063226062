import { EditSheet } from '@rubcube/react-spreadsheet-import';
import { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { ColumnDto, CreateOrderBodyDto, GetOrderItemsResponseDto } from '~/api/requests/types.gen';

import { translations } from './translations';

interface FilePickerModalProps {
  order?: GetOrderItemsResponseDto;
  mode: string;
  isLoading: boolean;
  isOpen: boolean;
  fields: ColumnDto[];
  document: string;
  onClose: () => void;
  onChangeDocument: (value: string) => void;
  onSearchDocument: () => void;
  onSubmit: (data: CreateOrderBodyDto[], isDeleting?: boolean) => Promise<void>;
}

export const OrderEditSheetModalLayout = ({
  order,
  mode,
  isLoading,
  isOpen,
  fields,
  document,
  onClose,
  onChangeDocument,
  onSearchDocument,
  onSubmit,
}: FilePickerModalProps): ReactElement => {
  const theme = useTheme();

  return (
    <EditSheet
      order={order}
      mode={mode}
      isLoading={isLoading}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
      document={document}
      onChangeDocument={onChangeDocument}
      onSearchDocument={onSearchDocument}
      fields={fields}
      translations={translations}
      allowInvalidSubmit={false}
      maxRecords={10000}
      customTheme={{
        colors: {
          rsi: {
            50: theme.designSystem.palette.primary.lightest,
            100: theme.designSystem.palette.primary.lightest,
            200: theme.designSystem.palette.primary.light,
            300: theme.designSystem.palette.primary.light,
            400: theme.designSystem.palette.primary.regular,
            500: theme.designSystem.palette.primary.regular,
            600: theme.designSystem.palette.primary.dark,
            700: theme.designSystem.palette.primary.dark,
            800: theme.designSystem.palette.primary.darkest,
            900: theme.designSystem.palette.primary.darkest,
          },
        },
        components: {
          Button: {
            baseStyle: {
              borderRadius: 36,
              padding: 6,
            },
          },
        },
      }}
    />
  );
};
