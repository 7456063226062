import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ScopeEnum } from '~/api/constants';
import { useUserScopes } from '~/hooks/use-user-scopes';
import { selectCurrentUser } from '~/redux/reducers/user';

import { ICombinedEmployeeHook } from './employee-list.types';
import { useArchiveEmployeeHook } from './hooks/use-archive-employee.hook';
import { useEmployeesEmailSheetHook } from './hooks/use-employees-email-sheet.hook';
import { useListEmployeesHook } from './hooks/use-list-employees.hook';

export const useEmployeeListHook = (): ICombinedEmployeeHook => {
  const email = useSelector(selectCurrentUser)?.email;

  const { hasScope } = useUserScopes();
  const {
    tab,
    employees,
    document,
    pagination,
    isLoading,
    onChangeDocument,
    onChangeTab,
    onRefetchEmployees,
    onSelectEmployee,
  } = useListEmployeesHook();
  const { state, onSendEmail, onChangeEmail, onResetState } = useEmployeesEmailSheetHook();
  const { selectedEmployees, isArchiving, onAchievementPress, onSelectAll, onSelectClick } =
    useArchiveEmployeeHook({
      tab,
      data: employees,
      onRefetch: onRefetchEmployees,
    });

  useEffect(() => {
    const savedScrollPosition = sessionStorage.getItem('scroll-position');
    if (savedScrollPosition) {
      window.scrollTo(0, Number(savedScrollPosition));
      sessionStorage.removeItem('scroll-position');
    }
    onResetState();
  }, []);

  return {
    tab,
    employees,
    email,
    document,
    pagination,
    state,
    selectedEmployees,
    isArchiving,
    isLoading,
    isEmployeeProfileViewable: hasScope(ScopeEnum.VIEW_PROFILE_EMPLOYEE),
    onAchievementPress,
    onChangeDocument,
    onChangeEmail,
    onChangeTab,
    onRefetchEmployees,
    onResetState,
    onSelectAll,
    onSelectClick,
    onSelectEmployee,
    onSendEmail,
  };
};
