import { IconographyNames } from '@vizir-banking/design-system';
import {
  Icon as BaseIcon,
  Link as BaseLink,
  TextField as BaseTextField,
} from '@vizir-banking/design-system/web';
import { noop } from 'lodash';
import styled from 'styled-components';

import { getColor } from '~/styles/theme/get-theme';

import {
  Notification as BaseNotification,
  NotificationLeadingContentType,
} from '../notification/notification';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid ${getColor('neutral', 'light')};
  background-color: ${getColor('neutral', 'lighter')};
  border-radius: 4px;
  gap: 8px;
  padding: 0 16px;
`;

export const LinkArea = styled(BaseLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: ${({ onClick }) => (onClick !== noop ? 'pointer' : 'default')};
  }
`;

export const SearchIcon = styled(BaseIcon).attrs(() => ({
  iconName: IconographyNames.search,
  color: getColor('neutral', 'darkest'),
}))`
  width: 16px;
  height: 16px;
`;

export const TextField = styled(BaseTextField).attrs(() => ({
  containerProps: {
    style: {
      width: '100%',
      flex: 1,
      border: 'none',
      paddingLeft: 0,
      display: 'flex',
      flexDirection: 'row',
    },
  },
  labelProps: {
    style: {
      backgroundColor: 'transparent',
    },
  },
  textInputProps: {
    style: {
      backgroundColor: 'transparent',
      display: 'flex',
      flexDirection: 'column',
    },
  },
}))``;

export const Notification = styled(BaseNotification).attrs(() => ({
  leadingContentType: NotificationLeadingContentType.ICON,
  leadingIcon: {
    iconName: IconographyNames.errorCircle,
    iconColor: getColor('error', 'medium'),
    iconSize: 16,
  },
  containerProps: {
    style: {
      width: '100%',
      alignItems: 'center',
      backgroundColor: 'transparent',
    },
  },
}))``;

export const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
`;
