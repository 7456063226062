import { IconographyNames } from '@vizir-banking/design-system';
import { Tabs } from '@vizir-banking/design-system/web';
import { noop } from 'lodash';
import { ReactElement } from 'react';

import { SimplifiedEmployees } from '~/api/requests';
import { ErrorDialog } from '~/components/dialogs/error-dialog/error-dialog';
import { TrailingContentType } from '~/components/page-header/page-header';
import { DynamicTable } from '~/components/tables/table.layout';
import { useTranslation } from '~/translates/use-translate';

import {
  Container,
  ContentArea,
  PageHeader,
  primarySuccessButtonStyleProps,
  Screen,
  Search,
  SendEmailDialog,
  sendEmailPrimaryButtonStyleProps,
  sendEmailTertiaryButtonStyleProps,
  SuccessDialog,
} from './employee-list.styles';
import { ButtonProps, ICombinedEmployeeHook } from './employee-list.types';

export const EmployeeListLayout = ({
  tab,
  employees,
  email,
  document,
  pagination,
  state,
  selectedEmployees,
  isArchiving,
  isLoading,
  isEmployeeProfileViewable,
  onAchievementPress,
  onChangeDocument,
  onChangeEmail,
  onChangeTab,
  onSelectAll,
  onSelectClick,
  onSelectEmployee,
  onSendEmail,
}: ICombinedEmployeeHook) => {
  const translate = useTranslation('pages.rh.employees.view');

  const { isSendEmailModalVisible, isSendEmailLoading, isSuccessModalVisible } = state;
  const { onReachEndLoading } = pagination;

  const tabs = ['active', 'archived'];

  const renderTable = () => {
    return (
      <DynamicTable<SimplifiedEmployees>
        data={employees ?? []}
        columns={['status', 'name', 'document', 'email']}
        isLoading={isLoading}
        isReachEndLoading={onReachEndLoading}
        onRowClick={isEmployeeProfileViewable ? onSelectEmployee : undefined}
        isSelect
        selectedEntities={selectedEmployees}
        onAchievementPress={onAchievementPress}
        onSelectAll={onSelectAll}
        onSelectClick={onSelectClick}
        isSelectLoading={isArchiving}
        currentTab={tab}
      />
    );
  };

  const renderContent = (key: number) => {
    const content: { [key: number]: ReactElement } = {
      0: renderTable(),
      1: renderTable(),
    };

    return content[key];
  };

  const renderItems = tabs.map((tab, index) => ({
    label: translate(`tabs.${tab}`),
    element: renderContent(index),
  }));

  const renderTabs = () => {
    return <Tabs items={renderItems} activeTabIndex={tab} onChange={onChangeTab} />;
  };

  const sendEmailPrimaryButtonProps: ButtonProps = {
    label: translate('sendEmailDialog.confirm'),
    onClick: () => onSendEmail(),
    containerProps: sendEmailPrimaryButtonStyleProps,
    isLoading: isSendEmailLoading,
  };

  const sendEmailTertiaryButtonProps: ButtonProps = {
    label: translate('sendEmailDialog.cancel'),
    onClick: () => onChangeEmail({ isSendEmailModalVisible: !isSendEmailModalVisible }),
    containerProps: sendEmailTertiaryButtonStyleProps,
  };

  const primarySuccessButtonProps: ButtonProps = {
    label: 'Ok',
    onClick: () => onChangeEmail({ isSuccessModalVisible: !isSuccessModalVisible }),
    containerProps: primarySuccessButtonStyleProps,
  };

  return (
    <Screen>
      <Container>
        <ContentArea>
          <PageHeader
            leadingText={translate('pageHeader.currentStep')}
            title={translate('pageHeader.subtitle')}
            trailingContentType={!isLoading && !employees?.length ? undefined : TrailingContentType.BUTTON}
            trailingButton={{
              label: translate('pageHeader.button'),
              onClick: () => onChangeEmail({ isSendEmailModalVisible: !isSendEmailModalVisible }),
              withLeadingIcon: true,
              leadingIconName: IconographyNames.email,
              isLoading: isSendEmailLoading || isLoading,
            }}
          />
          <Search
            onChange={onChangeDocument}
            onClear={() => onChangeDocument('')}
            value={document.value}
            placeholder='Buscar por CPF'
            onClickSearch={noop}
            error={document.error}
          />
          {renderTabs()}
        </ContentArea>
      </Container>

      <SendEmailDialog
        isVisible={isSendEmailModalVisible}
        onOutsideClick={() => onChangeEmail({ isSendEmailModalVisible: !isSendEmailModalVisible })}
        title={translate('sendEmailDialog.title', {
          email: email ?? '',
        })}
        primaryButtonProps={sendEmailPrimaryButtonProps}
        tertiaryButtonProps={sendEmailTertiaryButtonProps}
      >
        {translate('sendEmailDialog.content')}
      </SendEmailDialog>

      <SuccessDialog
        isVisible={isSuccessModalVisible}
        onOutsideClick={() => onChangeEmail({ isSuccessModalVisible: !isSuccessModalVisible })}
        title={translate('successDialog.title')}
        primaryButtonProps={primarySuccessButtonProps}
      />
      <ErrorDialog />
    </Screen>
  );
};
