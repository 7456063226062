import { IconographyNames } from '@vizir-banking/design-system';

import { ScopeEnum } from '~/api/constants';
import { BatchProposalJsonDto, GetProposalBatches } from '~/api/requests';
import { ErrorDialog } from '~/components/dialogs/error-dialog/error-dialog';
import { SuccessDialog } from '~/components/dialogs/success-dialog/success-dialog';
import { FilePickerModal } from '~/components/file-picker/file-picker-modal';
import { DynamicTable } from '~/components/tables/table.layout';
import { useTranslation } from '~/translates/use-translate';

import { ListBatchesProps } from './list-batches.hook';
import { PageHeader, Screen, Tabs } from './list-batches.styles';

export const BatchesViewLayout = ({
  tab,
  batches,
  isLoading,
  onReachEndLoading,
  handleBatchClick,
  onChangeTab,
  showAddCollaborators,
  handleShowAddCollaborators,
  onSubmitCollaborators,
  fields,
}: ListBatchesProps) => {
  const tabs = ['all', 'processing', 'processed'];
  const translate = useTranslation('pages.rh.listBatches');

  const renderContent = () => (
    <DynamicTable<GetProposalBatches>
      data={batches}
      columns={['status', 'id', 'createdAt']}
      onRowClick={(row) => handleBatchClick(row.id)}
      isLoading={isLoading}
      isReachEndLoading={onReachEndLoading}
      hasOrderColumn
    />
  );

  const renderTabs = tabs.map((label) => ({
    label: translate(`tabs.${label}`),
    element: renderContent(),
  }));

  return (
    <Screen>
      <PageHeader
        leadingText={translate('title')}
        title={translate('title')}
        verifyScope={{
          scope: ScopeEnum.ADD_EMPLOYEE,
        }}
        trailingButton={{
          label: translate('addCollaborators'),
          onClick: () => handleShowAddCollaborators(true),
          leadingIconName: IconographyNames.add,
        }}
      />
      <Tabs items={renderTabs} activeTabIndex={tab} onChange={onChangeTab} />
      <FilePickerModal<BatchProposalJsonDto>
        isOpen={showAddCollaborators}
        onClose={() => handleShowAddCollaborators(false)}
        fields={fields}
        onSubmit={onSubmitCollaborators}
      />
      <SuccessDialog />
      <ErrorDialog />
    </Screen>
  );
};
