import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { OrderStatusEnum, SplitModeEnum } from '~/api/constants';
import {
  useCommonServiceCommonControllerGetCsvColumns,
  useLoadOrderServiceOrderControllerListOrders,
  useLoadOrderServiceOrderControllerValidateOrder,
} from '~/api/queries';
import {
  ColumnDto,
  CreateOrderBodyDto,
  OpenAPI,
  Order,
  OrderStatusEnum as OrderStatusEnumType,
  ScopeEnum,
  SplitModeEnum as SplitModeType,
} from '~/api/requests';
import { useOnReachEnd } from '~/hooks/use-reach-end';
import { useUserScopes } from '~/hooks/use-user-scopes';
import { setDetails, setOrder } from '~/redux/reducers/new-recharge';
import { selectToken } from '~/redux/reducers/user';

import { RechargeSteps } from '../types';

export interface BalanceRechargeProps {
  tab: number;
  mode: SplitModeType;
  recharges: Order[];
  isLoading: boolean;
  isOnEndLoading: boolean;
  onChangeTab: (tabIndex: number) => void;
  hasScope: (scope: ScopeEnum) => boolean;
  showAddRecharge: boolean;
  handleShowAddRecharge: (value: boolean) => void;
  fields: ColumnDto[];
  onSubmitRecharge: (data: CreateOrderBodyDto[]) => Promise<void>;
  showEditOrder: boolean;
  orderId: string;
  onOpenEditOrder: (row: Order) => void;
  onCloseEditOrder: () => void;
}

interface UseBalanceProps {
  onChangeStep: (step: RechargeSteps) => void;
}

const ORDERS_PER_PAGE = 15;

export const useBalanceRecharge = ({ onChangeStep }: UseBalanceProps): BalanceRechargeProps => {
  const queryClient = useQueryClient();
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const { hasScope } = useUserScopes();

  const validateOrder = useLoadOrderServiceOrderControllerValidateOrder({
    onSuccess: (response) => {
      dispatch(setDetails(response));
      onChangeStep(RechargeSteps.NEW_RECHARGE);
    },
  });

  useOnReachEnd(() => {
    if (!onReachEndLoading && !isOrdersPending && data.length > 0 && enabled) {
      setOnReachEndLoading(true);
      setPage((prev) => prev + 1);
    }
  });

  useEffect(() => {
    OpenAPI.HEADERS = {
      ...OpenAPI.HEADERS,
      Authorization: `Bearer ${token}`,
    };
  }, [token]);

  const [page, setPage] = useState(1);
  const [data, setData] = useState<Order[]>([]);
  const [onReachEndLoading, setOnReachEndLoading] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const [tab, setTab] = useState(0);
  const [showAddRecharge, setShowAddRecharge] = useState(false);
  const [editOrder, setEditOrder] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [mode, setMode] = useState<SplitModeType>(SplitModeEnum.MANUAL);

  const onOpenEditOrder = (row: Order) => {
    setOrderId(row.id);
    setMode(row.splitMode);
    setEditOrder(true);
  };

  const onCloseEditOrder = () => {
    queryClient.removeQueries();
    queryClient.invalidateQueries();
    queryClient.resetQueries();
    setOrderId('');
    setEditOrder(false);
  };

  const onChangeTab = (tab: number) => {
    setTab(tab);
    setPage(1);
    setData([]);
    setEnabled(true);
    onOrdersRefetch();
  };

  const getStatus = (tabIndex: number) => {
    const status: { [key: number]: OrderStatusEnumType | undefined } = {
      0: undefined,
      1: OrderStatusEnum.PROCESSED,
      2: OrderStatusEnum.PENDING_PAYMENT,
      3: OrderStatusEnum.READY_TO_RELEASE,
      4: OrderStatusEnum.CANCELED,
    };

    return status[tabIndex];
  };

  const getFields = useCommonServiceCommonControllerGetCsvColumns({
    model: 'order',
  });

  const {
    data: orders,
    isPending: isOrdersPending,
    isRefetching: isOrdersRefetching,
    isError: isOrdersError,
    refetch: onOrdersRefetch,
  } = useLoadOrderServiceOrderControllerListOrders(
    {
      page,
      perPage: ORDERS_PER_PAGE,
      status: getStatus(tab),
    },
    [page, tab],
    { enabled }
  );

  const filterRecharges = (tabIndex: number): Order[] => {
    const statusMap: { [key: number]: OrderStatusEnumType[] } = {
      1: [OrderStatusEnum.PROCESSED, OrderStatusEnum.PARTIAL_PROCESSED],
      2: [OrderStatusEnum.PENDING_PAYMENT],
      3: [OrderStatusEnum.READY_TO_RELEASE],
      4: [OrderStatusEnum.CANCELED, OrderStatusEnum.FAILED],
    };
    const status = statusMap[tabIndex] ?? [];

    if (tabIndex === 0) return data;
    return data.filter((order) => status.includes(order.status));
  };

  useEffect(() => {
    if (orders) {
      const newBatches = orders?.data ?? [];
      page === 1 ? setData(newBatches) : setData((prev) => [...prev, ...newBatches]);
    }

    setOnReachEndLoading(false);

    if (isOrdersError || (orders?.data && orders.data.length < ORDERS_PER_PAGE)) {
      setEnabled(false);
    }
  }, [orders, page, tab]);

  useEffect(() => {
    setOnReachEndLoading(isOrdersPending && page > 1 && enabled);
  }, [isOrdersPending, page]);

  const handleShowAddRecharge = (value: boolean) => {
    setShowAddRecharge(value);
  };

  const onSubmitRecharge = async (data: CreateOrderBodyDto[]) => {
    dispatch(setOrder(data));
    await validateOrder.mutateAsync({ requestBody: { json: data } });
  };

  return {
    tab,
    mode,
    recharges: filterRecharges(tab),
    isLoading: (isOrdersPending || isOrdersRefetching) && page === 1 && enabled,
    isOnEndLoading: onReachEndLoading,
    onChangeTab,
    hasScope,
    showAddRecharge,
    handleShowAddRecharge,
    onSubmitRecharge,
    fields: getFields?.data ?? [],
    showEditOrder: editOrder,
    orderId,
    onOpenEditOrder,
    onCloseEditOrder,
  };
};
