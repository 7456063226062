import { ColumnDto } from '~/api/requests';

export const fields: ColumnDto[] = [
  {
    originalKey: '',
    label: 'pessoa_nome_completo',
    key: 'pessoa_nome_completo',
    fieldType: {
      type: 'input',
    },
    alternateMatches: ['pessoa_nome_completo'],
    example: 'João Silva Teste',
    validations: [
      {
        rule: 'regex',
        value: '^[a-zA-ZáéíóúÁÉÍÓÚãõÃÕâêîôûÂÊÎÔÛçÇ\\s]+$',
        errorMessage: 'pessoa_nome_completo inválido',
        level: 'error',
      },
    ],
  },
  {
    originalKey: '',
    label: 'nome_preferido',
    key: 'nome_preferido',
    fieldType: {
      type: 'input',
    },
    alternateMatches: ['nome_preferido'],
    example: 'João',
    validations: [
      {
        rule: 'regex',
        value: '^[a-zA-ZáéíóúÁÉÍÓÚãõÃÕâêîôûÂÊÎÔÛçÇ\\s]*$',
        errorMessage: 'nome_preferido inválido',
        level: 'error',
      },
    ],
  },
  {
    originalKey: '',
    label: 'sexo',
    key: 'sexo',
    fieldType: {
      type: 'input',
    },
    alternateMatches: ['sexo'],
    example: '3',
    validations: [
      {
        rule: 'regex',
        value: '^[3456]*$',
        errorMessage: 'sexo inválido',
        level: 'error',
      },
    ],
  },
  {
    originalKey: '',
    label: 'estado_civil',
    key: 'estado_civil',
    fieldType: {
      type: 'input',
    },
    alternateMatches: ['estado_civil'],
    example: '7',
    validations: [
      {
        rule: 'regex',
        value: '^(7|8|9|10)*$',
        errorMessage: 'estado_civil inválido',
        level: 'error',
      },
    ],
  },
  {
    originalKey: '',
    label: 'emancipado_menor',
    key: 'emancipado_menor',
    fieldType: {
      type: 'input',
    },
    alternateMatches: ['emancipado_menor'],
    example: 'FALSE',
    validations: [
      {
        rule: 'regex',
        value: '^(1|t|T|true|TRUE|True|0|f|F|false|FALSE|False)*$',
        errorMessage: 'emancipado_menor inválido',
        level: 'error',
      },
    ],
  },
  {
    originalKey: '',
    label: 'email',
    key: 'email',
    fieldType: {
      type: 'input',
    },
    alternateMatches: ['email'],
    example: 'joao.silva@exemplo.com',
    validations: [
      {
        rule: 'regex',
        value: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$',
        errorMessage: 'email inválido',
        level: 'error',
      },
    ],
  },
  {
    originalKey: '',
    label: 'cpf',
    key: 'cpf',
    fieldType: { type: 'input' },
    alternateMatches: ['person_id', 'cpf*', 'cpf'],
    example: '12345678900',
    validations: [
      { rule: 'required', errorMessage: 'cpf é obrigatório', level: 'error' },
      { rule: 'regex', value: '^[0-9]{11}$', errorMessage: 'cpf inválido', level: 'error' },
      { rule: 'unique', errorMessage: 'cpf duplicado', level: 'error' },
    ],
  },
  {
    label: 'nome_da_mae',
    key: 'nome_da_mae',
    originalKey: 'mother_name',
    fieldType: {
      type: 'input',
    },
    alternateMatches: ['nome_da_mae'],
    example: 'Maria Silva',
    validations: [
      {
        rule: 'regex',
        value: '^[a-zA-ZáéíóúÁÉÍÓÚãõÃÕâêîôûÂÊÎÔÛçÇ\\s]+$',
        errorMessage: 'nome_da_mae inválido',
        level: 'error',
      },
    ],
  },
  {
    label: 'data_nascimento',
    key: 'data_nascimento',
    originalKey: 'birth_date',
    fieldType: {
      type: 'input',
    },
    alternateMatches: ['data_nascimento'],
    example: '1998-08-23',
    validations: [
      {
        rule: 'regex',
        value: '^(19|20)\\d\\d-(0[1-9]|1[0-2])-(0[1-9]|[12]\\d|3[01])$',
        errorMessage: 'data_nascimento inválido',
        level: 'error',
      },
    ],
  },
  {
    originalKey: '',
    label: 'codigo_de_discagem_telefone',
    key: 'codigo_de_discagem_telefone',
    fieldType: {
      type: 'input',
    },
    alternateMatches: ['codigo_de_discagem_telefone'],
    example: '55',
    validations: [
      {
        rule: 'regex',
        value: '^\\s*$|^\\s*[0-9]+(\\.[0-9]{1,2})?\\s*$',
        errorMessage: 'aux_internet é inválido',
        level: 'error',
      },
    ],
  },
  {
    originalKey: '',
    label: 'codigo_de_area_telefone',
    key: 'codigo_de_area_telefone',
    fieldType: {
      type: 'input',
    },
    alternateMatches: ['codigo_de_area_telefone'],
    example: '11',
    validations: [
      {
        rule: 'regex',
        value: '^\\s*$|^\\s*[0-9]+(\\.[0-9]{1,2})?\\s*$',
        errorMessage: 'refeicao é inválido',
        level: 'error',
      },
    ],
  },
  {
    originalKey: '',
    label: 'numero_telefone',
    key: 'numero_telefone',
    fieldType: {
      type: 'input',
    },
    alternateMatches: ['numero_telefone'],
    example: '912345678',
    validations: [
      {
        rule: 'regex',
        value: '^\\s*$|^\\s*[0-9]+(\\.[0-9]{1,2})?\\s*$',
        errorMessage: 'gift_natal é inválido',
        level: 'error',
      },
    ],
  },
  {
    originalKey: '',
    label: 'codigo_postal_endereco',
    key: 'codigo_postal_endereco',
    fieldType: {
      type: 'input',
    },
    alternateMatches: ['codigo_postal_endereco'],
    example: '12345678',
    validations: [
      {
        rule: 'regex',
        value: '^\\d{8}$',
        errorMessage: 'codigo_postal_endereco inválido',
        level: 'error',
      },
    ],
  },
  {
    originalKey: '',
    label: 'rua_endereco',
    key: 'rua_endereco',
    fieldType: {
      type: 'input',
    },
    alternateMatches: ['rua_endereco'],
    example: 'Rua Exemplo',
    validations: [
      {
        rule: 'required',
        errorMessage: 'rua_endereco inválido',
        level: 'error',
      },
    ],
  },
  {
    originalKey: '',
    label: 'numero_endereco',
    key: 'numero_endereco',
    fieldType: {
      type: 'input',
    },
    alternateMatches: ['numero_endereco'],
    example: '123',
    validations: [
      {
        rule: 'required',
        errorMessage: 'numero_endereco inválido',
        level: 'error',
      },
    ],
  },
  {
    originalKey: '',
    label: 'complemento_endereco',
    key: 'complemento_endereco',
    fieldType: {
      type: 'input',
    },
    alternateMatches: ['complemento_endereco'],
    example: 'Apt 2',
  },
  {
    originalKey: '',
    label: 'bairro_endereco',
    key: 'bairro_endereco',
    fieldType: {
      type: 'input',
    },
    alternateMatches: ['bairro_endereco'],
    example: 'Bairro Exemplo',
    validations: [
      {
        rule: 'required',
        errorMessage: 'bairro_endereco inválido',
        level: 'error',
      },
    ],
  },
  {
    originalKey: '',
    label: 'cidade_endereco',
    key: 'cidade_endereco',
    fieldType: {
      type: 'input',
    },
    alternateMatches: ['cidade_endereco'],
    example: 'São Paulo',
    validations: [
      {
        rule: 'required',
        errorMessage: 'cidade_endereco inválido',
        level: 'error',
      },
    ],
  },
  {
    originalKey: '',
    label: 'codigo_area_administrativa_endereco',
    key: 'codigo_area_administrativa_endereco',
    fieldType: {
      type: 'input',
    },
    alternateMatches: ['codigo_area_administrativa_endereco'],
    example: 'SP',
    validations: [
      {
        rule: 'regex',
        value: '^[A-Za-z]{2}$',
        errorMessage: 'codigo_area_administrativa_endereco inválido',
        level: 'error',
      },
    ],
  },
  {
    originalKey: '',
    label: 'codigo_pais_endereco',
    key: 'codigo_pais_endereco',
    fieldType: {
      type: 'input',
    },
    alternateMatches: ['codigo_pais_endereco'],
    example: 'BR',
    validations: [
      {
        rule: 'regex',
        value: '^[A-Za-z]{2}$',
        errorMessage: 'codigo_pais_endereco inválido',
        level: 'error',
      },
    ],
  },
];
