import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { OrderStatusEnum, SplitModeEnum } from '~/api/constants';
import {
  useLoadOrderServiceOrderControllerOrderDetails,
  useLoadOrderServiceOrderControllerSplit,
} from '~/api/queries';
import { Order, OrderDetails } from '~/api/requests';
import { setError, setSuccess } from '~/redux/reducers/application';
import { ErrorsEnum } from '~/translates/error/types';
import { SuccessEnum } from '~/translates/success/types';

import { RechargeSteps } from './types';

interface IRecharge {
  order: Order;
  currentStep: RechargeSteps;
  isSplittingOrder: boolean;
  isReleaseOrderModalVisible: boolean;
  data: OrderDetails;
  isDetailsLoading: boolean;
  onRefetchDetails: () => Promise<void>;
  onSplitOrderSubmit: () => void;
  toggleReleaseOrderModal: () => void;
  onSelectOrder: (order: Order) => void;
  onChangeStep: (step: RechargeSteps) => void;
}

export type IFileData = Omit<OrderDetails, 'itemsQty'>;

const INITIAL_ORDER_STATE: Order = {
  id: '',
  status: OrderStatusEnum.UNKNOWN,
  amount: '',
  createdAt: '',
  splitMode: SplitModeEnum.MANUAL,
  splitScheduleDate: '',
};

export const useRecharge = (): IRecharge => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [currentStep, setCurrentStep] = useState(RechargeSteps.BALANCE);
  const [order, setOrder] = useState<Order>(INITIAL_ORDER_STATE);
  const [isReleaseOrderModalVisible, setIsReleaseOrderModalVisible] = useState(false);

  const { data, isLoading, isPending, refetch } = useLoadOrderServiceOrderControllerOrderDetails({
    id: order?.id,
  });

  const splitOrder = useLoadOrderServiceOrderControllerSplit();

  const onSuccess = () => {
    onChangeStep(RechargeSteps.BALANCE);
    queryClient.invalidateQueries();
    dispatch(setSuccess(SuccessEnum.SPLIT_ORDER));
    toggleReleaseOrderModal();
  };

  const onError = () => {
    dispatch(setError(ErrorsEnum.SPLIT_ORDER));
  };

  const onSplitOrderSubmit = () => {
    splitOrder.mutate(
      { id: order?.id },
      {
        onSuccess,
        onError,
      }
    );
  };

  const onChangeStep = (step: RechargeSteps) => {
    setCurrentStep(step);
  };

  const onSelectOrder = (order: Order) => setOrder(order);

  const toggleReleaseOrderModal = () => setIsReleaseOrderModalVisible(!isReleaseOrderModalVisible);

  const onRefetchDetails = async () => {
    await refetch();
  };

  return {
    order,
    currentStep,
    isReleaseOrderModalVisible,
    data: data ?? ({} as OrderDetails),
    isDetailsLoading: isLoading || isPending,
    isSplittingOrder: splitOrder.isPending,
    onRefetchDetails,
    onSplitOrderSubmit,
    toggleReleaseOrderModal,
    onChangeStep,
    onSelectOrder,
  };
};
