import { ButtonProps as BaseButtonProps } from '@vizir-banking/design-system/web';

import { ColumnDto, Order, OrderDetails } from '~/api/requests';

import { RechargeSteps } from '../types';

export type ButtonProps = Omit<BaseButtonProps, 'buttonSize' | 'buttonType'>;
export interface IRechargeDetailsLayout {
  onChangeStep: (step: RechargeSteps) => void;
  order: Order;
  isReleaseOrderModalVisible?: boolean;
  toggleReleaseOrderModal?: () => void;
  isSuccessModalVisible: boolean;
  toggleSuccessModal: () => void;
  data: OrderDetails;
  isLoading: boolean;
  isCanceledModalVisible: boolean;
  toggleCanceledModal: () => void;
  isCancelOrderLoading: boolean;
  handleCancelOrder: () => void;
  isSendStatementModalVisible: boolean;
  toggleSendStatementModal: () => void;
  sendStatement: () => void;
  isSendStatementLoading: boolean;
  showEditOrder: boolean;
  onOpenEditOrder: () => void;
  onCloseEditOrder: () => void;
  fields: ColumnDto[];
  isFieldsLoading: boolean;
}

export enum OrderBalancesEnum {
  foodMealBalance = 'foodMealBalance',
  foodBalance = 'foodBalance',
  mealBalance = 'mealBalance',
  cultureBalance = 'cultureBalance',
  fuelBalance = 'fuelBalance',
  transportBalance = 'transportBalance',
  mobilityBalance = 'mobilityBalance',
  travelBalance = 'travelBalance',
  recreationBalance = 'recreationBalance',
  educationBalance = 'educationBalance',
  healthBalance = 'healthBalance',
  homeOfficeBalance = 'homeOfficeBalance',
  generalBalance = 'generalBalance',
}

export enum PossibleContents {
  EMPLOYEES_QTY = 'employeesQty',
  RELEASE_ORDER = 'releaseOrder',
  STILL_PROCESSING = 'stillProcessing',
  PAY_ORDER = 'payOrder',
  CANCEL_ORDER = 'cancelOrder',
  SEND_STATEMENT = 'sendStatement',
  EDIT_ORDER = 'editOrder',
}
