// generated with @7nohe/openapi-react-query-codegen@undefined 

import { UseQueryResult } from "@tanstack/react-query";
import { ApplicationService, AuthService, BatchProposalService, CardsService, CommonService, DefaultService, EmployeeService, EmployerService, LoadOrderService, SecurityService, UserService } from "../requests/services.gen";
import { CardBatchSortEnum, OrderEnum, OrderStatusEnum, SortLegalPersonEnum } from "../requests/types.gen";
export type UserServiceUserControllerGetAllUsersDefaultResponse = Awaited<ReturnType<typeof UserService.userControllerGetAllUsers>>;
export type UserServiceUserControllerGetAllUsersQueryResult<TData = UserServiceUserControllerGetAllUsersDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useUserServiceUserControllerGetAllUsersKey = "UserServiceUserControllerGetAllUsers";
export const UseUserServiceUserControllerGetAllUsersKeyFn = ({ page, perPage }: {
  page?: number;
  perPage?: number;
} = {}, queryKey?: Array<unknown>) => [useUserServiceUserControllerGetAllUsersKey, ...(queryKey ?? [{ page, perPage }])];
export type UserServiceUserControllerGetUserDefaultResponse = Awaited<ReturnType<typeof UserService.userControllerGetUser>>;
export type UserServiceUserControllerGetUserQueryResult<TData = UserServiceUserControllerGetUserDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useUserServiceUserControllerGetUserKey = "UserServiceUserControllerGetUser";
export const UseUserServiceUserControllerGetUserKeyFn = (queryKey?: Array<unknown>) => [useUserServiceUserControllerGetUserKey, ...(queryKey ?? [])];
export type UserServiceUserControllerGetLoggedUserCompaniesDefaultResponse = Awaited<ReturnType<typeof UserService.userControllerGetLoggedUserCompanies>>;
export type UserServiceUserControllerGetLoggedUserCompaniesQueryResult<TData = UserServiceUserControllerGetLoggedUserCompaniesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useUserServiceUserControllerGetLoggedUserCompaniesKey = "UserServiceUserControllerGetLoggedUserCompanies";
export const UseUserServiceUserControllerGetLoggedUserCompaniesKeyFn = (queryKey?: Array<unknown>) => [useUserServiceUserControllerGetLoggedUserCompaniesKey, ...(queryKey ?? [])];
export type UserServiceUserControllerGetUserCompaniesDefaultResponse = Awaited<ReturnType<typeof UserService.userControllerGetUserCompanies>>;
export type UserServiceUserControllerGetUserCompaniesQueryResult<TData = UserServiceUserControllerGetUserCompaniesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useUserServiceUserControllerGetUserCompaniesKey = "UserServiceUserControllerGetUserCompanies";
export const UseUserServiceUserControllerGetUserCompaniesKeyFn = ({ userId }: {
  userId: string;
}, queryKey?: Array<unknown>) => [useUserServiceUserControllerGetUserCompaniesKey, ...(queryKey ?? [{ userId }])];
export type BatchProposalServiceBatchProposalControllerDownloadExampleDefaultResponse = Awaited<ReturnType<typeof BatchProposalService.batchProposalControllerDownloadExample>>;
export type BatchProposalServiceBatchProposalControllerDownloadExampleQueryResult<TData = BatchProposalServiceBatchProposalControllerDownloadExampleDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useBatchProposalServiceBatchProposalControllerDownloadExampleKey = "BatchProposalServiceBatchProposalControllerDownloadExample";
export const UseBatchProposalServiceBatchProposalControllerDownloadExampleKeyFn = (queryKey?: Array<unknown>) => [useBatchProposalServiceBatchProposalControllerDownloadExampleKey, ...(queryKey ?? [])];
export type BatchProposalServiceBatchProposalControllerGetProposalBatchesDefaultResponse = Awaited<ReturnType<typeof BatchProposalService.batchProposalControllerGetProposalBatches>>;
export type BatchProposalServiceBatchProposalControllerGetProposalBatchesQueryResult<TData = BatchProposalServiceBatchProposalControllerGetProposalBatchesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useBatchProposalServiceBatchProposalControllerGetProposalBatchesKey = "BatchProposalServiceBatchProposalControllerGetProposalBatches";
export const UseBatchProposalServiceBatchProposalControllerGetProposalBatchesKeyFn = ({ createdAt, page, perPage, proposalBatchId, statusId }: {
  createdAt?: string;
  page?: number;
  perPage?: number;
  proposalBatchId?: string;
  statusId?: number;
} = {}, queryKey?: Array<unknown>) => [useBatchProposalServiceBatchProposalControllerGetProposalBatchesKey, ...(queryKey ?? [{ createdAt, page, perPage, proposalBatchId, statusId }])];
export type BatchProposalServiceBatchProposalControllerGetSpecificBatchDefaultResponse = Awaited<ReturnType<typeof BatchProposalService.batchProposalControllerGetSpecificBatch>>;
export type BatchProposalServiceBatchProposalControllerGetSpecificBatchQueryResult<TData = BatchProposalServiceBatchProposalControllerGetSpecificBatchDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useBatchProposalServiceBatchProposalControllerGetSpecificBatchKey = "BatchProposalServiceBatchProposalControllerGetSpecificBatch";
export const UseBatchProposalServiceBatchProposalControllerGetSpecificBatchKeyFn = ({ document, id, page, perPage, proposalId, proposalOnboardingId, statusId }: {
  document?: string;
  id: string;
  page?: number;
  perPage?: number;
  proposalId?: string;
  proposalOnboardingId?: string;
  statusId?: number;
}, queryKey?: Array<unknown>) => [useBatchProposalServiceBatchProposalControllerGetSpecificBatchKey, ...(queryKey ?? [{ document, id, page, perPage, proposalId, proposalOnboardingId, statusId }])];
export type BatchProposalServiceBatchProposalControllerGetSpecificBatchStatusDefaultResponse = Awaited<ReturnType<typeof BatchProposalService.batchProposalControllerGetSpecificBatchStatus>>;
export type BatchProposalServiceBatchProposalControllerGetSpecificBatchStatusQueryResult<TData = BatchProposalServiceBatchProposalControllerGetSpecificBatchStatusDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useBatchProposalServiceBatchProposalControllerGetSpecificBatchStatusKey = "BatchProposalServiceBatchProposalControllerGetSpecificBatchStatus";
export const UseBatchProposalServiceBatchProposalControllerGetSpecificBatchStatusKeyFn = ({ id }: {
  id: string;
}, queryKey?: Array<unknown>) => [useBatchProposalServiceBatchProposalControllerGetSpecificBatchStatusKey, ...(queryKey ?? [{ id }])];
export type BatchProposalServiceBatchProposalControllerFileErrorsDefaultResponse = Awaited<ReturnType<typeof BatchProposalService.batchProposalControllerFileErrors>>;
export type BatchProposalServiceBatchProposalControllerFileErrorsQueryResult<TData = BatchProposalServiceBatchProposalControllerFileErrorsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useBatchProposalServiceBatchProposalControllerFileErrorsKey = "BatchProposalServiceBatchProposalControllerFileErrors";
export const UseBatchProposalServiceBatchProposalControllerFileErrorsKeyFn = ({ id }: {
  id: string;
}, queryKey?: Array<unknown>) => [useBatchProposalServiceBatchProposalControllerFileErrorsKey, ...(queryKey ?? [{ id }])];
export type BatchProposalServiceBatchProposalControllerGetSpecificBatchDetailsDefaultResponse = Awaited<ReturnType<typeof BatchProposalService.batchProposalControllerGetSpecificBatchDetails>>;
export type BatchProposalServiceBatchProposalControllerGetSpecificBatchDetailsQueryResult<TData = BatchProposalServiceBatchProposalControllerGetSpecificBatchDetailsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useBatchProposalServiceBatchProposalControllerGetSpecificBatchDetailsKey = "BatchProposalServiceBatchProposalControllerGetSpecificBatchDetails";
export const UseBatchProposalServiceBatchProposalControllerGetSpecificBatchDetailsKeyFn = ({ id }: {
  id: string;
}, queryKey?: Array<unknown>) => [useBatchProposalServiceBatchProposalControllerGetSpecificBatchDetailsKey, ...(queryKey ?? [{ id }])];
export type BatchProposalServiceBatchProposalControllerReprocessDefaultResponse = Awaited<ReturnType<typeof BatchProposalService.batchProposalControllerReprocess>>;
export type BatchProposalServiceBatchProposalControllerReprocessQueryResult<TData = BatchProposalServiceBatchProposalControllerReprocessDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useBatchProposalServiceBatchProposalControllerReprocessKey = "BatchProposalServiceBatchProposalControllerReprocess";
export const UseBatchProposalServiceBatchProposalControllerReprocessKeyFn = ({ id, onlyDeclined, onlyErrors }: {
  id: string;
  onlyDeclined?: boolean;
  onlyErrors?: boolean;
}, queryKey?: Array<unknown>) => [useBatchProposalServiceBatchProposalControllerReprocessKey, ...(queryKey ?? [{ id, onlyDeclined, onlyErrors }])];
export type SecurityServiceSecurityControllerGetRequestListDefaultResponse = Awaited<ReturnType<typeof SecurityService.securityControllerGetRequestList>>;
export type SecurityServiceSecurityControllerGetRequestListQueryResult<TData = SecurityServiceSecurityControllerGetRequestListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useSecurityServiceSecurityControllerGetRequestListKey = "SecurityServiceSecurityControllerGetRequestList";
export const UseSecurityServiceSecurityControllerGetRequestListKeyFn = ({ method, path }: {
  method?: string;
  path?: string;
} = {}, queryKey?: Array<unknown>) => [useSecurityServiceSecurityControllerGetRequestListKey, ...(queryKey ?? [{ method, path }])];
export type EmployeeServiceEmployeeControllerListEmployeesDefaultResponse = Awaited<ReturnType<typeof EmployeeService.employeeControllerListEmployees>>;
export type EmployeeServiceEmployeeControllerListEmployeesQueryResult<TData = EmployeeServiceEmployeeControllerListEmployeesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useEmployeeServiceEmployeeControllerListEmployeesKey = "EmployeeServiceEmployeeControllerListEmployees";
export const UseEmployeeServiceEmployeeControllerListEmployeesKeyFn = ({ document, email, isArchived, name, order, page, perPage, sort, status }: {
  document?: string;
  email?: string;
  isArchived?: boolean;
  name?: string;
  order?: OrderEnum;
  page?: number;
  perPage?: number;
  sort?: string;
  status?: string;
} = {}, queryKey?: Array<unknown>) => [useEmployeeServiceEmployeeControllerListEmployeesKey, ...(queryKey ?? [{ document, email, isArchived, name, order, page, perPage, sort, status }])];
export type EmployeeServiceEmployeeControllerGetEmployeesSheetViaEmailDefaultResponse = Awaited<ReturnType<typeof EmployeeService.employeeControllerGetEmployeesSheetViaEmail>>;
export type EmployeeServiceEmployeeControllerGetEmployeesSheetViaEmailQueryResult<TData = EmployeeServiceEmployeeControllerGetEmployeesSheetViaEmailDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useEmployeeServiceEmployeeControllerGetEmployeesSheetViaEmailKey = "EmployeeServiceEmployeeControllerGetEmployeesSheetViaEmail";
export const UseEmployeeServiceEmployeeControllerGetEmployeesSheetViaEmailKeyFn = (queryKey?: Array<unknown>) => [useEmployeeServiceEmployeeControllerGetEmployeesSheetViaEmailKey, ...(queryKey ?? [])];
export type EmployeeServiceEmployeeControllerGetEmployeeDefaultResponse = Awaited<ReturnType<typeof EmployeeService.employeeControllerGetEmployee>>;
export type EmployeeServiceEmployeeControllerGetEmployeeQueryResult<TData = EmployeeServiceEmployeeControllerGetEmployeeDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useEmployeeServiceEmployeeControllerGetEmployeeKey = "EmployeeServiceEmployeeControllerGetEmployee";
export const UseEmployeeServiceEmployeeControllerGetEmployeeKeyFn = ({ id }: {
  id: string;
}, queryKey?: Array<unknown>) => [useEmployeeServiceEmployeeControllerGetEmployeeKey, ...(queryKey ?? [{ id }])];
export type DefaultServiceFileControllerGetSignedUrlDefaultResponse = Awaited<ReturnType<typeof DefaultService.fileControllerGetSignedUrl>>;
export type DefaultServiceFileControllerGetSignedUrlQueryResult<TData = DefaultServiceFileControllerGetSignedUrlDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useDefaultServiceFileControllerGetSignedUrlKey = "DefaultServiceFileControllerGetSignedUrl";
export const UseDefaultServiceFileControllerGetSignedUrlKeyFn = ({ key }: {
  key: string;
}, queryKey?: Array<unknown>) => [useDefaultServiceFileControllerGetSignedUrlKey, ...(queryKey ?? [{ key }])];
export type DefaultServiceFileControllerDownloadFileDefaultResponse = Awaited<ReturnType<typeof DefaultService.fileControllerDownloadFile>>;
export type DefaultServiceFileControllerDownloadFileQueryResult<TData = DefaultServiceFileControllerDownloadFileDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useDefaultServiceFileControllerDownloadFileKey = "DefaultServiceFileControllerDownloadFile";
export const UseDefaultServiceFileControllerDownloadFileKeyFn = ({ key }: {
  key: string;
}, queryKey?: Array<unknown>) => [useDefaultServiceFileControllerDownloadFileKey, ...(queryKey ?? [{ key }])];
export type LoadOrderServiceOrderControllerListOrdersDefaultResponse = Awaited<ReturnType<typeof LoadOrderService.orderControllerListOrders>>;
export type LoadOrderServiceOrderControllerListOrdersQueryResult<TData = LoadOrderServiceOrderControllerListOrdersDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useLoadOrderServiceOrderControllerListOrdersKey = "LoadOrderServiceOrderControllerListOrders";
export const UseLoadOrderServiceOrderControllerListOrdersKeyFn = ({ createdAt, loadOrderId, page, perPage, status }: {
  createdAt?: string;
  loadOrderId?: string;
  page?: number;
  perPage?: number;
  status?: OrderStatusEnum;
} = {}, queryKey?: Array<unknown>) => [useLoadOrderServiceOrderControllerListOrdersKey, ...(queryKey ?? [{ createdAt, loadOrderId, page, perPage, status }])];
export type LoadOrderServiceOrderControllerOrderDetailsDefaultResponse = Awaited<ReturnType<typeof LoadOrderService.orderControllerOrderDetails>>;
export type LoadOrderServiceOrderControllerOrderDetailsQueryResult<TData = LoadOrderServiceOrderControllerOrderDetailsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useLoadOrderServiceOrderControllerOrderDetailsKey = "LoadOrderServiceOrderControllerOrderDetails";
export const UseLoadOrderServiceOrderControllerOrderDetailsKeyFn = ({ id }: {
  id: string;
}, queryKey?: Array<unknown>) => [useLoadOrderServiceOrderControllerOrderDetailsKey, ...(queryKey ?? [{ id }])];
export type LoadOrderServiceOrderControllerReprocessDefaultResponse = Awaited<ReturnType<typeof LoadOrderService.orderControllerReprocess>>;
export type LoadOrderServiceOrderControllerReprocessQueryResult<TData = LoadOrderServiceOrderControllerReprocessDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useLoadOrderServiceOrderControllerReprocessKey = "LoadOrderServiceOrderControllerReprocess";
export const UseLoadOrderServiceOrderControllerReprocessKeyFn = ({ id }: {
  id: string;
}, queryKey?: Array<unknown>) => [useLoadOrderServiceOrderControllerReprocessKey, ...(queryKey ?? [{ id }])];
export type LoadOrderServiceOrderControllerGetOrderDetailsViaEmailDefaultResponse = Awaited<ReturnType<typeof LoadOrderService.orderControllerGetOrderDetailsViaEmail>>;
export type LoadOrderServiceOrderControllerGetOrderDetailsViaEmailQueryResult<TData = LoadOrderServiceOrderControllerGetOrderDetailsViaEmailDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useLoadOrderServiceOrderControllerGetOrderDetailsViaEmailKey = "LoadOrderServiceOrderControllerGetOrderDetailsViaEmail";
export const UseLoadOrderServiceOrderControllerGetOrderDetailsViaEmailKeyFn = ({ id }: {
  id: string;
}, queryKey?: Array<unknown>) => [useLoadOrderServiceOrderControllerGetOrderDetailsViaEmailKey, ...(queryKey ?? [{ id }])];
export type LoadOrderServiceOrderControllerGetStatementDefaultResponse = Awaited<ReturnType<typeof LoadOrderService.orderControllerGetStatement>>;
export type LoadOrderServiceOrderControllerGetStatementQueryResult<TData = LoadOrderServiceOrderControllerGetStatementDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useLoadOrderServiceOrderControllerGetStatementKey = "LoadOrderServiceOrderControllerGetStatement";
export const UseLoadOrderServiceOrderControllerGetStatementKeyFn = ({ id }: {
  id: string;
}, queryKey?: Array<unknown>) => [useLoadOrderServiceOrderControllerGetStatementKey, ...(queryKey ?? [{ id }])];
export type LoadOrderServiceOrderControllerGetStatementPdfViaEmailDefaultResponse = Awaited<ReturnType<typeof LoadOrderService.orderControllerGetStatementPdfViaEmail>>;
export type LoadOrderServiceOrderControllerGetStatementPdfViaEmailQueryResult<TData = LoadOrderServiceOrderControllerGetStatementPdfViaEmailDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useLoadOrderServiceOrderControllerGetStatementPdfViaEmailKey = "LoadOrderServiceOrderControllerGetStatementPdfViaEmail";
export const UseLoadOrderServiceOrderControllerGetStatementPdfViaEmailKeyFn = ({ id }: {
  id: string;
}, queryKey?: Array<unknown>) => [useLoadOrderServiceOrderControllerGetStatementPdfViaEmailKey, ...(queryKey ?? [{ id }])];
export type LoadOrderServiceOrderControllerGetOrderItemsDefaultResponse = Awaited<ReturnType<typeof LoadOrderService.orderControllerGetOrderItems>>;
export type LoadOrderServiceOrderControllerGetOrderItemsQueryResult<TData = LoadOrderServiceOrderControllerGetOrderItemsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useLoadOrderServiceOrderControllerGetOrderItemsKey = "LoadOrderServiceOrderControllerGetOrderItems";
export const UseLoadOrderServiceOrderControllerGetOrderItemsKeyFn = ({ cpf, id }: {
  cpf: string;
  id: string;
}, queryKey?: Array<unknown>) => [useLoadOrderServiceOrderControllerGetOrderItemsKey, ...(queryKey ?? [{ cpf, id }])];
export type EmployerServiceEmployerControllerGetAccountThatWillReceivePaymentsDefaultResponse = Awaited<ReturnType<typeof EmployerService.employerControllerGetAccountThatWillReceivePayments>>;
export type EmployerServiceEmployerControllerGetAccountThatWillReceivePaymentsQueryResult<TData = EmployerServiceEmployerControllerGetAccountThatWillReceivePaymentsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useEmployerServiceEmployerControllerGetAccountThatWillReceivePaymentsKey = "EmployerServiceEmployerControllerGetAccountThatWillReceivePayments";
export const UseEmployerServiceEmployerControllerGetAccountThatWillReceivePaymentsKeyFn = (queryKey?: Array<unknown>) => [useEmployerServiceEmployerControllerGetAccountThatWillReceivePaymentsKey, ...(queryKey ?? [])];
export type EmployerServiceEmployerControllerGetEmployerAddressesDefaultResponse = Awaited<ReturnType<typeof EmployerService.employerControllerGetEmployerAddresses>>;
export type EmployerServiceEmployerControllerGetEmployerAddressesQueryResult<TData = EmployerServiceEmployerControllerGetEmployerAddressesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useEmployerServiceEmployerControllerGetEmployerAddressesKey = "EmployerServiceEmployerControllerGetEmployerAddresses";
export const UseEmployerServiceEmployerControllerGetEmployerAddressesKeyFn = (queryKey?: Array<unknown>) => [useEmployerServiceEmployerControllerGetEmployerAddressesKey, ...(queryKey ?? [])];
export type EmployerServiceEmployerControllerGetEmployerBasicInfoDefaultResponse = Awaited<ReturnType<typeof EmployerService.employerControllerGetEmployerBasicInfo>>;
export type EmployerServiceEmployerControllerGetEmployerBasicInfoQueryResult<TData = EmployerServiceEmployerControllerGetEmployerBasicInfoDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useEmployerServiceEmployerControllerGetEmployerBasicInfoKey = "EmployerServiceEmployerControllerGetEmployerBasicInfo";
export const UseEmployerServiceEmployerControllerGetEmployerBasicInfoKeyFn = (queryKey?: Array<unknown>) => [useEmployerServiceEmployerControllerGetEmployerBasicInfoKey, ...(queryKey ?? [])];
export type EmployerServiceEmployerControllerGetEmployerAccountBalanceDefaultResponse = Awaited<ReturnType<typeof EmployerService.employerControllerGetEmployerAccountBalance>>;
export type EmployerServiceEmployerControllerGetEmployerAccountBalanceQueryResult<TData = EmployerServiceEmployerControllerGetEmployerAccountBalanceDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useEmployerServiceEmployerControllerGetEmployerAccountBalanceKey = "EmployerServiceEmployerControllerGetEmployerAccountBalance";
export const UseEmployerServiceEmployerControllerGetEmployerAccountBalanceKeyFn = (queryKey?: Array<unknown>) => [useEmployerServiceEmployerControllerGetEmployerAccountBalanceKey, ...(queryKey ?? [])];
export type EmployerServiceEmployerControllerListEmployeesDefaultResponse = Awaited<ReturnType<typeof EmployerService.employerControllerListEmployees>>;
export type EmployerServiceEmployerControllerListEmployeesQueryResult<TData = EmployerServiceEmployerControllerListEmployeesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useEmployerServiceEmployerControllerListEmployeesKey = "EmployerServiceEmployerControllerListEmployees";
export const UseEmployerServiceEmployerControllerListEmployeesKeyFn = ({ document, isArchived, name, order, page, perPage, sort, status }: {
  document?: string;
  isArchived?: boolean;
  name?: string;
  order?: OrderEnum;
  page?: number;
  perPage?: number;
  sort?: SortLegalPersonEnum;
  status?: string;
} = {}, queryKey?: Array<unknown>) => [useEmployerServiceEmployerControllerListEmployeesKey, ...(queryKey ?? [{ document, isArchived, name, order, page, perPage, sort, status }])];
export type EmployerServiceEmployerControllerGetEmployeeDefaultResponse = Awaited<ReturnType<typeof EmployerService.employerControllerGetEmployee>>;
export type EmployerServiceEmployerControllerGetEmployeeQueryResult<TData = EmployerServiceEmployerControllerGetEmployeeDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useEmployerServiceEmployerControllerGetEmployeeKey = "EmployerServiceEmployerControllerGetEmployee";
export const UseEmployerServiceEmployerControllerGetEmployeeKeyFn = ({ id }: {
  id: string;
}, queryKey?: Array<unknown>) => [useEmployerServiceEmployerControllerGetEmployeeKey, ...(queryKey ?? [{ id }])];
export type CardsServiceCardsControllerListBatchesDefaultResponse = Awaited<ReturnType<typeof CardsService.cardsControllerListBatches>>;
export type CardsServiceCardsControllerListBatchesQueryResult<TData = CardsServiceCardsControllerListBatchesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useCardsServiceCardsControllerListBatchesKey = "CardsServiceCardsControllerListBatches";
export const UseCardsServiceCardsControllerListBatchesKeyFn = ({ employerId, externalId, order, page, perPage, sort, status }: {
  employerId?: string;
  externalId?: string;
  order?: OrderEnum;
  page?: number;
  perPage?: number;
  sort?: CardBatchSortEnum;
  status?: "processing" | "processed" | "pending" | "error" | "aborted" | "retry";
} = {}, queryKey?: Array<unknown>) => [useCardsServiceCardsControllerListBatchesKey, ...(queryKey ?? [{ employerId, externalId, order, page, perPage, sort, status }])];
export type CardsServiceCardsControllerGetExpirationDateDefaultResponse = Awaited<ReturnType<typeof CardsService.cardsControllerGetExpirationDate>>;
export type CardsServiceCardsControllerGetExpirationDateQueryResult<TData = CardsServiceCardsControllerGetExpirationDateDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useCardsServiceCardsControllerGetExpirationDateKey = "CardsServiceCardsControllerGetExpirationDate";
export const UseCardsServiceCardsControllerGetExpirationDateKeyFn = (queryKey?: Array<unknown>) => [useCardsServiceCardsControllerGetExpirationDateKey, ...(queryKey ?? [])];
export type CardsServiceCardsControllerGetBatchCardStatusDefaultResponse = Awaited<ReturnType<typeof CardsService.cardsControllerGetBatchCardStatus>>;
export type CardsServiceCardsControllerGetBatchCardStatusQueryResult<TData = CardsServiceCardsControllerGetBatchCardStatusDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useCardsServiceCardsControllerGetBatchCardStatusKey = "CardsServiceCardsControllerGetBatchCardStatus";
export const UseCardsServiceCardsControllerGetBatchCardStatusKeyFn = ({ externalId }: {
  externalId: string;
}, queryKey?: Array<unknown>) => [useCardsServiceCardsControllerGetBatchCardStatusKey, ...(queryKey ?? [{ externalId }])];
export type CommonServiceCommonControllerGetAddressByZipCodeDefaultResponse = Awaited<ReturnType<typeof CommonService.commonControllerGetAddressByZipCode>>;
export type CommonServiceCommonControllerGetAddressByZipCodeQueryResult<TData = CommonServiceCommonControllerGetAddressByZipCodeDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useCommonServiceCommonControllerGetAddressByZipCodeKey = "CommonServiceCommonControllerGetAddressByZipCode";
export const UseCommonServiceCommonControllerGetAddressByZipCodeKeyFn = ({ zipCode }: {
  zipCode: string;
}, queryKey?: Array<unknown>) => [useCommonServiceCommonControllerGetAddressByZipCodeKey, ...(queryKey ?? [{ zipCode }])];
export type CommonServiceCommonControllerGetCsvColumnsDefaultResponse = Awaited<ReturnType<typeof CommonService.commonControllerGetCsvColumns>>;
export type CommonServiceCommonControllerGetCsvColumnsQueryResult<TData = CommonServiceCommonControllerGetCsvColumnsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useCommonServiceCommonControllerGetCsvColumnsKey = "CommonServiceCommonControllerGetCsvColumns";
export const UseCommonServiceCommonControllerGetCsvColumnsKeyFn = ({ model }: {
  model: string;
}, queryKey?: Array<unknown>) => [useCommonServiceCommonControllerGetCsvColumnsKey, ...(queryKey ?? [{ model }])];
export type ApplicationServiceApplicationControllerGetVersionsDefaultResponse = Awaited<ReturnType<typeof ApplicationService.applicationControllerGetVersions>>;
export type ApplicationServiceApplicationControllerGetVersionsQueryResult<TData = ApplicationServiceApplicationControllerGetVersionsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useApplicationServiceApplicationControllerGetVersionsKey = "ApplicationServiceApplicationControllerGetVersions";
export const UseApplicationServiceApplicationControllerGetVersionsKeyFn = (queryKey?: Array<unknown>) => [useApplicationServiceApplicationControllerGetVersionsKey, ...(queryKey ?? [])];
export type UserServiceUserControllerCreateMutationResult = Awaited<ReturnType<typeof UserService.userControllerCreate>>;
export type UserServiceUserControllerResendEmailMutationResult = Awaited<ReturnType<typeof UserService.userControllerResendEmail>>;
export type UserServiceUserControllerAssociateUserWithCompaniesMutationResult = Awaited<ReturnType<typeof UserService.userControllerAssociateUserWithCompanies>>;
export type AuthServiceAuthControllerLoginByDocumentPasswordMutationResult = Awaited<ReturnType<typeof AuthService.authControllerLoginByDocumentPassword>>;
export type AuthServiceAuthControllerExistsMutationResult = Awaited<ReturnType<typeof AuthService.authControllerExists>>;
export type AuthServiceAuthControllerChangePasswordMutationResult = Awaited<ReturnType<typeof AuthService.authControllerChangePassword>>;
export type AuthServiceAuthControllerLoginBySwithRoleMutationResult = Awaited<ReturnType<typeof AuthService.authControllerLoginBySwithRole>>;
export type BatchProposalServiceBatchProposalControllerCreateBatchProposalMutationResult = Awaited<ReturnType<typeof BatchProposalService.batchProposalControllerCreateBatchProposal>>;
export type BatchProposalServiceBatchProposalControllerEnqueueBatchProposalMutationResult = Awaited<ReturnType<typeof BatchProposalService.batchProposalControllerEnqueueBatchProposal>>;
export type SecurityServiceSecurityControllerCheckMutationResult = Awaited<ReturnType<typeof SecurityService.securityControllerCheck>>;
export type DefaultServiceFileControllerUploadFileMutationResult = Awaited<ReturnType<typeof DefaultService.fileControllerUploadFile>>;
export type LoadOrderServiceOrderControllerCreateOrderMutationResult = Awaited<ReturnType<typeof LoadOrderService.orderControllerCreateOrder>>;
export type LoadOrderServiceOrderControllerCancelOrderMutationResult = Awaited<ReturnType<typeof LoadOrderService.orderControllerCancelOrder>>;
export type LoadOrderServiceOrderControllerSplitMutationResult = Awaited<ReturnType<typeof LoadOrderService.orderControllerSplit>>;
export type LoadOrderServiceOrderControllerValidateOrderMutationResult = Awaited<ReturnType<typeof LoadOrderService.orderControllerValidateOrder>>;
export type EmployerServiceEmployerControllerCreateEmployerAddressMutationResult = Awaited<ReturnType<typeof EmployerService.employerControllerCreateEmployerAddress>>;
export type EmployerServiceEmployerControllerCreateEmployeeMutationResult = Awaited<ReturnType<typeof EmployerService.employerControllerCreateEmployee>>;
export type CardsServiceCardsControllerCreateBatchMutationResult = Awaited<ReturnType<typeof CardsService.cardsControllerCreateBatch>>;
export type UserServiceUserControllerUpdateMutationResult = Awaited<ReturnType<typeof UserService.userControllerUpdate>>;
export type SecurityServiceSecurityControllerValidateMutationResult = Awaited<ReturnType<typeof SecurityService.securityControllerValidate>>;
export type EmployeeServiceEmployeeControllerUpdateEmployeeMutationResult = Awaited<ReturnType<typeof EmployeeService.employeeControllerUpdateEmployee>>;
export type EmployeeServiceEmployeeControllerUpdateArchivedStatusEmployeeMutationResult = Awaited<ReturnType<typeof EmployeeService.employeeControllerUpdateArchivedStatusEmployee>>;
export type EmployerServiceEmployerControllerUpdateEmployerAddressMutationResult = Awaited<ReturnType<typeof EmployerService.employerControllerUpdateEmployerAddress>>;
export type EmployerServiceEmployerControllerUpdateEmployeeStatusMutationResult = Awaited<ReturnType<typeof EmployerService.employerControllerUpdateEmployeeStatus>>;
export type LoadOrderServiceOrderControllerUpdateItemsMutationResult = Awaited<ReturnType<typeof LoadOrderService.orderControllerUpdateItems>>;
export type EmployerServiceEmployerControllerUpdateEmployeeMutationResult = Awaited<ReturnType<typeof EmployerService.employerControllerUpdateEmployee>>;
export type UserServiceUserControllerDeleteMutationResult = Awaited<ReturnType<typeof UserService.userControllerDelete>>;
export type UserServiceUserControllerDisassociateUserFromCompaniesMutationResult = Awaited<ReturnType<typeof UserService.userControllerDisassociateUserFromCompanies>>;
