import { ReactElement } from 'react';

import { ColumnDto } from '~/api/requests/types.gen';

import { useOrderEditHook } from './order-edit.hook';
import { OrderEditSheetModalLayout } from './order-edit.layout';

interface FilePickerModalProps {
  id: string;
  isOpen: boolean;
  fields: ColumnDto[];
  mode: string;
  onClose: () => void;
}

export const OrderEditSheetModal = ({
  id,
  mode,
  isOpen,
  fields,
  onClose,
}: FilePickerModalProps): ReactElement => {
  const properties = useOrderEditHook({ id, fields });

  return (
    <OrderEditSheetModalLayout
      {...properties}
      isOpen={isOpen}
      onClose={onClose}
      fields={fields}
      mode={mode}
    />
  );
};
