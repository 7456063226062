import { MouseEvent, useEffect, useState } from 'react';

import { useEmployeeServiceEmployeeControllerUpdateArchivedStatusEmployee } from '~/api/queries';
import { SimplifiedEmployees } from '~/api/requests';
import { SelectType } from '~/components/tables/types';

import { IArchiveEmployeeProperties, IArchiveEmployeesHook } from '../employee-list.types';

export const useArchiveEmployeeHook = ({
  tab,
  data,
  onRefetch,
}: IArchiveEmployeeProperties): IArchiveEmployeesHook => {
  const [selectedEmployees, setSelectedEmployees] = useState<SelectType<SimplifiedEmployees>[]>([]);

  const onArchiveEmployeeQuery = useEmployeeServiceEmployeeControllerUpdateArchivedStatusEmployee({
    onSuccess: async () => {
      setSelectedEmployees([]);
      await onRefetch();
    },
  });

  useEffect(() => setSelectedEmployees([]), [tab]);

  const onArchive = () => {
    onArchiveEmployeeQuery.mutateAsync({
      requestBody: {
        employees: selectedEmployees.map((employee) => ({
          personId: employee.row.personId,
          isArchived: !tab,
        })),
      },
    });
  };

  const onAchievementPress = () => {
    if (!selectedEmployees?.length) return;
    onArchive();
  };

  const onSelectAll = () => {
    const allSelected = selectedEmployees.length === data.length;
    setSelectedEmployees(allSelected ? [] : data.map((row, index) => ({ index, row, value: true })));
  };

  const onSelectClick = (e: MouseEvent<HTMLDivElement>, row: SimplifiedEmployees, index: number) => {
    e.stopPropagation();

    setSelectedEmployees((prev) => {
      if (prev.find((item) => item.index === index)) {
        return prev.filter((item) => item.index !== index);
      }

      return [...prev, { index, row, value: true }];
    });
  };

  return {
    selectedEmployees,
    isArchiving: onArchiveEmployeeQuery.isPending,
    onAchievementPress,
    onSelectAll,
    onSelectClick,
  };
};
